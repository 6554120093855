import { createSlice } from '@reduxjs/toolkit'

const initialState = true

export const networkStatusSlice = createSlice({
	name: 'networkStatus',
	initialState,
	reducers: {
		updateNetworkStatus: (_state, actions) => actions.payload
	}
})

// Action creators are generated for each case reducer function
export const { updateNetworkStatus } = networkStatusSlice.actions

export const networkStatusReducer = networkStatusSlice.reducer
