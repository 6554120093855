/** @jsx jsx **/
/** @jsxImportSource @emotion/react **/
import { Box } from '@mui/material'

const Icon = ({ children, color, fontSize, block, sx, ascent = 'textDark' }) => {
	return (
		<Box
			sx={{
				'& .MuiSvgIcon-root': {
					color: (theme) => (color ? color : theme.palette.ascents[ascent]),
					fontSize: fontSize,
					display: block ? 'block' : 'inherit'
				},

				color: (theme) => (color ? color : theme.palette.ascents[ascent]),
				fontSize: fontSize,
				display: block ? 'block' : 'inherit',
				...sx
			}}
			component='span'
		>
			{children}
		</Box>
	)
}

export default Icon
