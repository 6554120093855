import { createSlice } from '@reduxjs/toolkit'
import { getRoleLists } from './reducer'

const initialState = {
	allIds: [],
	byId: {}
}

export const roleListsSlice = createSlice({
	name: 'roles',
	initialState,
	extraReducers: {
		[getRoleLists.fulfilled]: (_, action) => action.payload
	}
})

export const roleListsReducer = roleListsSlice.reducer
