import AddIcCallOutlinedIcon from '@mui/icons-material/AddIcCallOutlined'
import { Box } from '@mui/material'
import Icon from '../Icon'
import Text from '../Text'

const ScheduleACallButton = ({ ascent = 'primary', textAscent = 'washLight', ...props }) => {
	return (
		<Box
			px={1.125}
			py={1.125}
			mx={2}
			my={1}
			sx={{
				background: 'transparent',
				border: (theme) => `1px solid ${theme.palette.ascents[ascent]}`,
				borderRadius: '0.5rem',
				transition: 'background 0.2s ease-in-out'
			}}
			display='flex'
			alignItems='center'
			component={'a'}
			href='https://calendly.com/getsecurewithzeron'
			target='_blank'
			{...props}
		>
			<Box mr={3}>
				<Icon ascent={textAscent} block>
					<AddIcCallOutlinedIcon />
				</Icon>
			</Box>

			<Box>
				<Text letterSpacing={1} fontWeight={500} ascent={textAscent} isCustomColor>
					Schedule a call
				</Text>
			</Box>
		</Box>
	)
}

export default ScheduleACallButton
