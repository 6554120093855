// Event Categories
const HOVER = 'hover'
const FULL_SCREEN = 'full_screen'
const TOGGLED = 'toggled'
const DOWNLOAD_DATA = 'download_data'
const AUTHENTICATION = 'authentication'
const TIMEFRAME_CHANGED = 'timeframe_changed'
const SEARCH = 'search'
const EXCEPTION = 'exception'
const INTERACTION = 'interaction'
const SCROLL = 'scroll'
const NAVIGATION = 'navigation'
const SWITCH = 'switch'
const REFRESH = 'refresh'
const DRAWER = 'drawer open'
const SCAN = 'scan'
const PAGE_VISIT = 'Page visit'
const TAB_VISIT = 'Tab visit'
const GLOBAL_SEARCH = 'Global Search'

// ACTIONS
const TOGGLED_MODAL = 'Toggled Modal'
const ACCORDIAN_TOGGLED = 'accordian_toggled'
const FORM_SUBMIT = 'form_submit'
const FORM_SUBMIT_SUCCESS = 'form_submit_success'
const FORM_SUBMIT_FAILED = 'form_submit_failed'
const GRAPH_FULL_SCREEN_ON = 'graph_full_screen_on'
const GRAPH_FULL_SCREEN_OFF = 'graph_full_screen_off'
const GRAPH_HOVER_IN = 'graph_hover_in'
const GRAPH_HOVER_OUT = 'graph_hover_out'
const PAGE_REFRESHED = 'page_refreshed'
const PAGE_CRASHED = 'page_crashed'
const HOST_SWITCHED = 'host_switched'
const COMPLIANCE_OPTION_SWITCHED = 'compliance_option_switched'
const COMPLIANCE_DOCUMENT_DOWNLOADED = 'compliance_document_downloaded'
const DURATION_CHANGED_IN_TOOLBAR = 'duration_changed_in_toolbar'
const BUTTON_CLICKED = 'button clicked'
const DRAWER_OPENED = 'drawer opened'
const TAB_SWITCHED = 'tab_switched'
const SIGNED_IN = 'signed_in'
const SIGNED_OUT = 'signed_out'
const PASSWORD_RESET = 'password_reset'
const GRAPH_LEGEND_TOGGLED_ON = 'graph_legend_toggled_on'
const GRAPH_LEGEND_TOGGLED_OFF = 'graph_legend_toggled_off'
const GRAPH_SWITCHED_TABS = 'graph_switched_tabs'
const GRAPH_TABLE_SCROLL = 'graph_table_scroll'
const SCANNING_SUCCESS = 'scanning_success'
const SCANNING_FAILED = 'scanning_failed'
const TABLE_SCROLL = 'table_scroll'
const TABLE_SEARCH = 'table_search'
const TABLE_DATA_DOWNLOAD = 'table_data_download'
const TABLE_COLUMNS_TOGGLED_ON = 'table_columns_toggled_on'
const TABLE_COLUMNS_TOGGLED_OFF = 'table_columns_toggled_off'
const TABLE_FILTER_DATA = 'table_filter_data'

export const customEvents = {
	TOGGLED_MODAL,
	ACCORDIAN_TOGGLED,
	FORM_SUBMIT,
	FORM_SUBMIT_SUCCESS,
	FORM_SUBMIT_FAILED,
	GRAPH_FULL_SCREEN_ON,
	GRAPH_FULL_SCREEN_OFF,
	GRAPH_HOVER_IN,
	GRAPH_HOVER_OUT,
	PAGE_REFRESHED,
	PAGE_CRASHED,
	HOST_SWITCHED,
	COMPLIANCE_OPTION_SWITCHED,
	COMPLIANCE_DOCUMENT_DOWNLOADED,
	DURATION_CHANGED_IN_TOOLBAR,
	BUTTON_CLICKED,
	DRAWER_OPENED,
	TAB_SWITCHED,
	SIGNED_IN,
	SIGNED_OUT,
	PASSWORD_RESET,
	GRAPH_LEGEND_TOGGLED_ON,
	GRAPH_LEGEND_TOGGLED_OFF,
	GRAPH_SWITCHED_TABS,
	GRAPH_TABLE_SCROLL,
	SCANNING_SUCCESS,
	SCANNING_FAILED,
	TABLE_SCROLL,
	TABLE_SEARCH,
	TABLE_DATA_DOWNLOAD,
	TABLE_COLUMNS_TOGGLED_ON,
	TABLE_COLUMNS_TOGGLED_OFF,
	TABLE_FILTER_DATA,
	PAGE_VISIT,
	TAB_VISIT,
	GLOBAL_SEARCH
}

export const categories = {
	HOVER,
	FULL_SCREEN,
	TOGGLED,
	DOWNLOAD_DATA,
	AUTHENTICATION,
	TIMEFRAME_CHANGED,
	SEARCH,
	EXCEPTION,
	INTERACTION,
	NAVIGATION,
	SCROLL,
	SWITCH,
	REFRESH,
	DRAWER,
	SCAN
}

export const optionsByAction = {
	[ACCORDIAN_TOGGLED]: {
		event_category: categories.TOGGLED
	},
	[FORM_SUBMIT]: {
		event_category: categories.INTERACTION
	},
	[FORM_SUBMIT_SUCCESS]: {
		event_category: categories.INTERACTION
	},
	[FORM_SUBMIT_FAILED]: {
		event_category: categories.INTERACTION
	},
	[GRAPH_FULL_SCREEN_ON]: {
		event_category: categories.FULL_SCREEN
	},
	[GRAPH_FULL_SCREEN_OFF]: {
		event_category: categories.FULL_SCREEN
	},
	[GRAPH_HOVER_IN]: {
		event_category: categories.HOVER
	},
	[GRAPH_HOVER_OUT]: {
		event_category: categories.HOVER
	},
	[PAGE_REFRESHED]: {
		event_category: categories.REFRESH
	},
	[PAGE_CRASHED]: {
		event_category: categories.EXCEPTION
	},
	[HOST_SWITCHED]: {
		event_category: categories.SWITCH
	},
	[COMPLIANCE_OPTION_SWITCHED]: {
		event_category: categories.SWITCH
	},
	[COMPLIANCE_DOCUMENT_DOWNLOADED]: {
		event_category: categories.DOWNLOAD_DATA
	},
	[DURATION_CHANGED_IN_TOOLBAR]: {
		event_category: categories.TIMEFRAME_CHANGED
	},
	[BUTTON_CLICKED]: {
		event_category: categories.INTERACTION
	},
	[DRAWER_OPENED]: {
		event_category: categories.DRAWER
	},
	[TAB_SWITCHED]: {
		event_category: categories.SWITCH
	},
	[SIGNED_IN]: {
		event_category: categories.AUTHENTICATION
	},
	[SIGNED_OUT]: {
		event_category: categories.AUTHENTICATION
	},
	[PASSWORD_RESET]: {
		event_category: categories.AUTHENTICATION
	},
	[GRAPH_LEGEND_TOGGLED_ON]: {
		event_category: categories.TOGGLED
	},
	[GRAPH_LEGEND_TOGGLED_OFF]: {
		event_category: categories.TOGGLED
	},
	[GRAPH_SWITCHED_TABS]: {
		event_category: categories.NAVIGATION
	},
	[GRAPH_TABLE_SCROLL]: {
		event_category: categories.SCROLL
	},
	[SCANNING_SUCCESS]: {
		event_category: categories.SCAN
	},
	[SCANNING_FAILED]: {
		event_category: categories.SCAN
	},
	[TABLE_SCROLL]: {
		event_category: categories.SCROLL
	},
	[TABLE_SEARCH]: {
		event_category: categories.SEARCH
	},
	[TABLE_DATA_DOWNLOAD]: {
		event_category: categories.DOWNLOAD_DATA
	},
	[TABLE_COLUMNS_TOGGLED_ON]: {
		event_category: categories.TOGGLED
	},
	[TABLE_COLUMNS_TOGGLED_OFF]: {
		event_category: categories.TOGGLED
	},
	[TABLE_FILTER_DATA]: {
		event_category: categories.INTERACTION
	}
}

export const pageTrackingEventNames = {
	Dashboard: 'Dashboard'
}
