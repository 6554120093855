/* eslint-disable no-unused-vars */
import SidebarOrderedListConstant from '@constants/SidebarOrderedListConstant'
import adminRoutesConstant from '@constants/adminRoutesConstant'
import { Box, Drawer, List, Tooltip } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import clsx from 'clsx'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { matchPath } from 'react-router'
import { Link, useLocation } from 'react-router-dom'
import BuyZeronButton from '../Button/BuyZeronButton'
import ScheduleACallButton from '../Button/ScheduleACallButton'
import Logo from '../Logo'
// import navMenuList from '@constants/navMenuList';
import { dependentPermissionsForSidebarGrouping } from '@constants/permissions'
import { getHealthOfServer } from '@services/graphService'
import { ASCENTS } from '@styles/theme'
import { SvgIcons } from '@utils/icons'
import { fadeColor } from '@utils/styles'
import Collapsible from '../Collapsible/Collapsible'
import Text from '../Text'
import SidebarOrganisation from './SidebarOrganisation'
import UserProfileBar from './UserProfileBar'
const drawerWidth = '18%'

const CustomDBHealthTooltip = ({ activeShards, unassignedShards }) => {
	return (
		<Box display={'flex'} flexDirection='column' minWidth={'15em'} padding='0.75rem'>
			<Text fontSize={'1.2em'} fontWeight={700} letterSpacing={1.5} align='center'>
				Cluster Shards
			</Text>
			<hr />
			<Box display={'flex'} justifyContent='space-evenly'>
				<Box display={'flex'} flexDirection='column'>
					<Text>Active</Text>
					<Text>Inactive</Text>
					<hr />
					<Box>
						<Text>Total</Text>
					</Box>
				</Box>
				<Box display={'flex'} flexDirection='column'>
					<Text>Shards</Text>
					<Text>Shards</Text>
					<hr />
					<Box>
						<Text>Shards</Text>
					</Box>
				</Box>
				<Box display={'flex'} flexDirection='column' marginLeft={'1em'}>
					<Text>{activeShards || '0'}</Text>
					<Text>{unassignedShards || '0'}</Text>
					<hr />
					<Box>
						<Text>{activeShards + unassignedShards || '0'}</Text>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

const Sidebar = ({ openSidebar, ascent }) => {
	const [navMenuList, setNavMenuList] = React.useState([])
	const dispatch = useDispatch()

	const [nestedMenuListByParent, setNestedMenuListByParent] = React.useState({})

	// eslint-disable-next-line no-unused-vars
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [showMoreHealthInfo, setShowMoreHealthInfo] = React.useState(true)
	const userRedux = useSelector((state) => state.auth.user.info)
	const paidSubscriptionDetails = useSelector((state) => state.auth.paidSubscriptionDetails.info)
	const [showSubsriptionLoader, setShowSubsriptionLoader] = React.useState(!paidSubscriptionDetails)
	const policies = useSelector((state) => state.auth.allRouteNamesByPolicy)
	const policyByRouteName = useSelector((state) => state.auth.policyByRouteName)

	const loggedInOrgID = userRedux?.organization?.id

	const isDemoUser = policies?.includes?.('DemoAccount')

	const location = useLocation()

	const [healthReport, setHealthReport] = React.useState({
		managerHealth: {
			running: 0,
			total: 0,
			detail: {}
		},
		databaseHealth: { active_shards_percent_as_number: 0 }
	})

	const setMenuItems = (data, condition) => {
		let navMenuByRole =
			// data.permissions &&
			// data.permissions.permission_ids &&
			// data.permissions.permission_ids
			// 	.map((each) => {
			// 		const route = each['route_name']
			data.role &&
			data.role.policy_list &&
			data.role.policy_list
				.map((each) => {
					const route = each['RouteName']

					if (route) {
						const routeObject = adminRoutesConstant.adminPaths[route]
						if (routeObject && routeObject.isShownOnSidebar) {
							return routeObject
						}
					}
				})
				.filter((each) => !!each)

		const dependentNavMenu = Object.keys(dependentPermissionsForSidebarGrouping)
			.map((dependentKey) => {
				const dependentUponKeysList = dependentPermissionsForSidebarGrouping[dependentKey]
				let allowThisDependentKey = false

				for (let index = 0; index < dependentUponKeysList.length; index++) {
					const dependentUponKey = dependentUponKeysList[index]
					if (policyByRouteName[dependentUponKey]) {
						allowThisDependentKey = true
						break
					}
				}

				if (allowThisDependentKey) {
					const routeObject = adminRoutesConstant.adminPaths[dependentKey]
					if (routeObject && routeObject.isShownOnSidebar) {
						return routeObject
					}
				}
			})
			.filter((each) => !!each)

		const _navSubMenu =
			data.role &&
			data.role.policy_list &&
			data.role.policy_list.reduce((obj, each) => {
				const route = each['RouteName']
				const routeObject = adminRoutesConstant.adminPaths[route]

				// data.permissions &&
				// data.permissions.permission_ids &&
				// data.permissions.permission_ids.reduce((obj, each) => {
				// 	const route = each['route_name']
				// 	const routeObject = adminRoutesConstant.adminPaths[route]

				if (routeObject && !!routeObject.parentRoute) {
					const parentRoute = adminRoutesConstant.adminPaths[routeObject.parentRoute]

					if (!parentRoute.isShownOnSidebar) return obj

					if (!obj[routeObject.parentRoute]) {
						obj[routeObject.parentRoute] = []
					}

					return {
						...obj,
						[routeObject.parentRoute]: obj[routeObject.parentRoute].concat(routeObject)
					}
				}
				return obj
			}, {})

		const navSubMenu = {}

		for (const key in _navSubMenu) {
			navSubMenu[key] = _navSubMenu[key].filter((subItem) => !!subItem && !subItem.hideItem)
		}

		const allNavMenuByRole = navMenuByRole?.concat(dependentNavMenu) || []

		if (condition) {
			return setNavMenuList(
				allNavMenuByRole.sort(function (a, b) {
					return SidebarOrderedListConstant.indexOf(a.key) - SidebarOrderedListConstant.indexOf(b.key)
				})
			)
		}
		setNavMenuList(
			allNavMenuByRole?.sort(function (a, b) {
				return SidebarOrderedListConstant.indexOf(a.key) - SidebarOrderedListConstant.indexOf(b.key)
			}) || []
		)
		setNestedMenuListByParent(navSubMenu)
	}

	const pathName = location.pathname

	const enableMoreDetails = () => {
		setShowMoreHealthInfo(true)
	}
	const updatedMenuItems = (condition) => {
		setMenuItems(userRedux, condition)
	}

	const handlePopoverOpen = (event) => {
		event.stopPropagation()

		setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	const manageHealthInsights = React.useMemo(() => {
		let running = 0,
			stopped = 0,
			total = 0
		if (healthReport?.managerHealth?.detail) {
			const values = Object.values(healthReport?.managerHealth?.detail)
			total = values.length
			for (let i = 0; i < values.length; i++) {
				const item = values[i]

				if (item.toLowerCase() === 'running') {
					running += 1
				} else if (item.toLowerCase() === 'stopped') {
					stopped += 1
				}
			}
		}

		return { running, stopped, total }
	}, [healthReport?.managerHealth?.detail])

	const RenderListItem = ({ item, noImage, subNavs }) => {
		// eslint-disable-next-line no-unused-vars
		const isMatching =
			pathName.split('/').length > 3
				? null
				: !!matchPath(
					{
						path: item.path,
						end: true
					},
					pathName
				)

		return openSidebar ? (
			<ListItem
				button
				key={item.path}
				// component={item.path.includes(pathName.split('/')[1]) && pathName.includes(item.path) ? "div" : Link}

				component={
					!!matchPath(
						{
							path: item.path,
							end: false
						},
						pathName
					) || !!subNavs
						? 'div'
						: Link
				}
				// selected={(item.path.includes(pathName.split('/')[1]) && pathName.includes(item.path))}

				selected={
					!!matchPath(
						{
							path: item.path,
							end: false
						},
						pathName
					)
				}
				to={subNavs ? undefined : typeof item.path === 'string' ? item.path : item.path.concat().pop()}
				onClick={() => { localStorage.removeItem('previousRoute') }}
				sx={{
					margin: 0,
					marginBottom: '0.5rem',
					textDecoration: 'none',
					'&:last-child': {
						marginBottom: '0rem'
					},
					'&.MuiListItem-root': {
						color: (theme) => theme.palette.ascents.greyBlue,
						display: 'flex',
						justifyContent: openSidebar ? 'flex-start !important' : 'inherit'
					},
					'&.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
						color: (theme) => theme.palette.ascents.white
					},
					'& .MuiTypography-body1': {
						fontFamily: '"Open Sans", sans-serif',
						// fontWeight: "600 !important",
						fontWeight: '500 !important',
						'&:hover,&:active,&:focus': {
							fontFamily: '"Open Sans", sans-serif'
						}
					}
				}}
			>
				{!noImage && item.Icon && <item.Icon className='icons' />}

				<Text
					ascent='inherit'
					margin={!item.Icon ? '0 0 0 1.25rem !important' : '0'}
					className={!item.Icon ? 'activeSubMenuIndicator' : ''}
				>
					{item.label}
				</Text>
			</ListItem>
		) : (
			<Tooltip
				title={
					<>
						<Text ascent='textDark' textTransform='uppercase' variant='body2' fontSize={'12px !important'}>
							{item.label}
						</Text>

						{subNavs && (
							<Box
								sx={{
									'& .MuiListItem-root': {
										backgroundColor: 'transparent',
										padding: '0.75rem',
										margin: '0 !important',
										color: (theme) => theme.palette.ascents.base,
										borderRadius: '0.5rem',
										letterSpacing: '1px',
										borderBottom: '0 !important',
										justifyContent: 'inherit',
										fontWeight: '400 !important',
										transition: 'all 250ms',

										'& .MuiTypography-body1': {
											fontWeight: '400 !important',
											fontSize: '0.875rem'
										},

										'& svg': {
											color: (theme) => theme.palette.ascents.greyBlue,
											'&:not(.MuiSvgIcon-root)': {
												marginRight: openSidebar ? '12px' : '0 !important'
											}
										}
									},

									'& .MuiListItem-root.Mui-selected, & .MuiListItem-root:hover': {
										color: (theme) => theme.palette.ascents.white,
										backgroundColor: (theme) => theme.palette.ascents.base,
										opacity: 1,

										'& .MuiTypography-body1': {
											fontWeight: '400 !important'
										},

										'& .MuiSvgIcon-root': {
											color: (theme) => theme.palette.ascents.base
										}
									}
								}}
								mt={'0.5rem'}
							>
								{subNavs.map((navItem) => {
									return (
										<ListItem
											button
											key={navItem.path}
											// component={navItem.path.includes(pathName.split('/')[1]) && pathName.includes(navItem.path) ? "div" : Link}

											component={
												matchPath(
													{
														path: navItem.path,
														end: false
													},
													pathName
												)
													? 'div'
													: Link
											}
											// selected={(navItem.path.includes(pathName.split('/')[1]) && pathName.includes(navItem.path))}

											selected={
												!!matchPath(
													{
														path: navItem.path,
														end: false
													},
													pathName
												)
											}
											to={typeof navItem.path === 'string' ? navItem.path : navItem.path.concat().pop()}
											sx={{
												margin: 0,
												marginBottom: '0.5rem',
												textDecoration: 'none',

												'&:last-child': {
													marginBottom: '0rem'
												},
												'&.MuiListItem-root': {
													color: (theme) => theme.palette.ascents.base,
													display: 'flex',
													justifyContent: openSidebar ? 'center' : 'inherit'
												},
												'&.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
													color: (theme) => theme.palette.ascents.white
												},
												'& .MuiTypography-body1': {
													fontFamily: '"Open Sans", sans-serif',
													// fontWeight: "600 !important",
													fontWeight: '500 !important',
													'&:hover, &:active, &:focus': {
														fontFamily: '"Open Sans", sans-serif'
													}
												}
											}}
										>
											{navItem.label}
										</ListItem>
									)
								})}
							</Box>
						)}
					</>
				}
				placement='right'
				componentsProps={{
					tooltip: {
						sx: {
							backgroundColor: (theme) => `${theme.palette.ascents.white} !important`,
							borderRadius: '8px !important',
							border: 'none !important',
							minWidth: subNavs ? '18rem' : 'inherit',
							padding: '0.625rem 0.75rem'
						}
					}
				}}
				arrow={false}
				interactive
			>
				<ListItem
					button
					key={item.path}
					// component={item.path.includes(pathName.split('/')[1]) && pathName.includes(item.path) ? "div" : Link}

					component={
						!!matchPath(
							{
								path: item.path,
								end: false
							},
							pathName
						) || !!subNavs
							? 'div'
							: Link
					}
					// selected={(item.path.includes(pathName.split('/')[1]) && pathName.includes(item.path))}

					selected={
						!!matchPath(
							{
								path: item.path,
								end: false
							},
							pathName
						)
					}
					to={subNavs ? undefined : typeof item.path === 'string' ? item.path : item.path.concat().pop()}
					sx={{ textDecoration: 'none', display: 'flex !important' }}
				>
					{!noImage && item.Icon && <item.Icon className='icons' />}
				</ListItem>
			</Tooltip>
		)
	}

	React.useEffect(() => {
		updatedMenuItems(isDemoUser)
	}, [JSON.stringify(userRedux), isDemoUser])

	React.useEffect(() => {
		// dispatch(updateSubscriptionDetails())
		getHealthOfServer()
			.api()
			.then((response) => {
				if (response && !response.error) {
					// const affectedItems = Object.values(response.zensor?.affected_items[0] || {})
					// const running = affectedItems.filter((item) => item === 'running').length
					// const dbHealth = response.opensearch
					// setHealthReport({
					// 	managerHealth: {
					// 		running,
					// 		total: affectedItems.length,
					// 		detail: response.zensor?.affected_items[0] || {}
					// 	},
					// 	databaseHealth: { ...dbHealth }
					// })
				}
			})
	}, [])

	// React.useEffect(() => {
	// 	const timeoutId = setTimeout(() => {
	// 		setShowSubsriptionLoader(false)
	// 	}, 5000)

	// 	return () => {
	// 		clearTimeout(timeoutId)
	// 	}
	// }, [])

	return (
		<Drawer
			variant='permanent'
			sx={{
				width: openSidebar ? drawerWidth : '5rem',
				transition: 'all 0.2s ease',
				marginLeft: 0,
				flexShrink: 0,
				whiteSpace: 'nowrap',
				zIndex: 1206,
				position: 'relative',
				'& .MuiList-root': {
					flex: '1 1 auto',
					// borderTop: `1px solid ${theme.palette.ascents.secondary}`,
					// borderBottom: `1px solid ${theme.palette.ascents.secondary}`,
					padding: '0 1rem',

					'& a': {
						display: 'flex'
					}
				},

				'& .MuiListItem-root': {
					backgroundColor: 'transparent',
					padding: '0.75rem',
					// margin: "0 !important",
					color: (theme) => theme.palette.ascents.white,
					borderRadius: '0.5rem',
					// letterSpacing: "1px",
					borderBottom: '0 !important',
					opacity: 0.7,

					'& .MuiTypography-body1': {
						fontWeight: '400 !important',
						fontSize: '0.875rem'
					},

					'& svg': {
						color: (theme) => theme.palette.ascents.greyBlue,
						'&:not(.MuiSvgIcon-root)': {
							marginRight: openSidebar ? '12px' : '0 !important'
						}
					}
				},

				'& .MuiListItem-root.Mui-selected, & .MuiListItem-root:hover': {
					color: (theme) => theme.palette.ascents.white,
					backgroundColor: (theme) => fadeColor(theme.palette.ascents.primary, 0.7),
					opacity: 1,

					'& .MuiTypography-body1': {
						fontWeight: '400'
					},

					'& .MuiSvgIcon-root': {
						color: (theme) => theme.palette.ascents.white
					}

					// '&.activeSubMenuIndicator::before': {
					//     content: '""',
					//     width: 14,
					//     height: 14,
					//     backgroundColor: theme.palette.ascents.white,
					//     borderRadius: 100,
					//     // position: 'absolute',
					//     // right: 0
					// }
				},
				'& .MuiCollapse-root': {
					backgroundColor: (theme) => `${fadeColor(theme.palette.ascents.primary, 0.7)} !important`,
					borderRadius: '0 0 8px 8px',
					marginBottom: '0.5rem',

					'& .MuiListItem-root.Mui-selected, & .MuiListItem-root:hover': {
						color: (theme) => theme.palette.ascents.white,
						backgroundColor: ASCENTS.trans,
						opacity: 1,

						'& .MuiTypography-body1': {
							fontWeight: '400'
							// opacity: 1,
						},

						'& .MuiSvgIcon-root': {
							color: (theme) => theme.palette.ascents.white
							// opacity: 1,
						}
					}
				},

				'& .MuiCollapse-wrapperInner': {
					padding: '0.75rem'
				},

				'& .collapseToggler': {
					borderRadius: '8px',
					'&:hover, &.activeCollapse': {
						opacity: 1,
						backgroundColor: (theme) => `${fadeColor(theme.palette.ascents.primary, 0.7)} !important`,
						'& .MuiListItemText-root, svg': {
							opacity: 1
						}
					},

					'& .MuiListItemText-root': {
						margin: 0
					}
				},

				'& .collapseOpen': {
					borderRadius: '8px 8px 0 0',
					backgroundColor: (theme) => `${fadeColor(theme.palette.ascents.primary, 0.7)} !important`,
					opacity: 1,
					'& .MuiListItemText-root, svg': {
						opacity: 0.7
					}
				},

				'& .activeItem, & .collapseOpen': {
					backgroundColor: (theme) => `${fadeColor(theme.palette.ascents.primary, 0.7)} !important`
				},

				'& .MuiListItem-root.Mui-selected': {
					position: 'relative',
					'& .indicator': {
						position: 'absolute',
						right: -1,
						width: '42px',
						height: '30px',
						background: '#fff',
						borderRadius: '36px',

						transition: 'transform 0.5s ease-in-out',
						transform: 'scaleX(0.5) rotateY(90deg)',
						transformOrigin: 'right',

						'& .MuiSvgIcon-root': {
							position: 'absolute',
							top: '50%',
							left: 0,
							transform: 'translate(0%,-50%)',
							zIndex: 1206,
							color: '#101418',
							marginRight: 0
						},

						'& .top': {
							position: 'absolute',
							right: 0,
							width: '50%',
							height: '300%',
							background: '#fff',
							top: '50%',
							transform: 'translate(0%, -50%)'
						},

						'& .bottom': {},

						'&:after, &:before': {
							content: "''",
							position: 'absolute',
							right: 0,
							height: '170%',
							borderRadius: '30px',
							zIndex: 1,
							width: '80%',
							backgroundColor: (theme) => theme.palette.ascents[ascent]
						},

						'&:before': {
							top: '1px',
							transform: 'translate(0%, -99%) rotate(0deg)'
						},

						'&:after': {
							bottom: '1px',
							transform: 'translate(0%, 99%) rotate(0deg)'
						}
					}
				},

				'& .MuiDrawer-paper': {
					backgroundColor: (theme) => theme.palette.ascents[ascent],
					position: 'relative',
					padding: (theme) => theme.spacing(0),

					overflow: 'visible'
				},

				'& .icons': {
					width: '1.25rem',
					height: '1.25rem',
					display: 'block',
					marginRight: openSidebar ? '12px !important' : '0 !important'
				}
			}}
			id='sidebar'
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					padding: '1rem 0',
					'& img': {
						marginTop: '1.5rem'
					}
				}}
			>
				{openSidebar ? <Logo logoType={'full_white'} width={'8rem'} /> : <img src={SvgIcons.LogoSmallWhiteSvg} />}
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					padding: 0,
					'& img': {
						marginTop: '1.5rem'
					}
				}}
			>
				{openSidebar ? (
					<img
						src='https://static.zeron.cloud/vpulse/images/VendorPulse.png'
						alt='Vendor Pulse'
						style={{ marginTop: '-1rem', marginBottom: '-0.5rem', width: '38%', height: 'auto' }}
					/>
				) : (
					''
				)}
			</Box>
			<SidebarOrganisation openSidebar={openSidebar} organisationName={Object.byString(userRedux, 'organization.name')} />

			<Box
				pt={0}
				pb={4}
				sx={{
					overflow: 'auto',
					flex: '1 1 auto',
					display: 'flex',
					flexDirection: 'column'
				}}
			>
				<List>
					{openSidebar &&
						navMenuList.length > 0 &&
						navMenuList.map((item, index) => {
							let children = nestedMenuListByParent[item.routeName]
							children = Array.isArray(children)
								? children.sort(function (a, b) {
									// Compare the 2 dates
									if (a.order < b.order) return -1
									if (a.order > b.order) return 1
									return 0
								})
								: children

							return Array.isArray(children) ? (
								<Collapsible
									key={index}
									expandIconPostion={'end'}
									label={item.label}
									isForRoute
									iconName={item.Icon && <item.Icon className='icons' />}
									labelProps={{
										isCustomColor: true,
										ascent: 'inherit'
									}}
									className={clsx({
										activeCollapse: !!matchPath(
											{
												path: item.path,
												end: true
											},
											'/'.concat(pathName.split('/')[1])
										)
									})}
									unMountOnExit
								>
									{children.map((childItem, index) => {
										return <RenderListItem key={index} item={childItem} noImage />
									})}
								</Collapsible>
							) : (
								<RenderListItem item={item} />
							)
						})}
					{!openSidebar &&
						navMenuList.length > 0 &&
						navMenuList.map((item) => {
							const children = nestedMenuListByParent[item.routeName]

							return <RenderListItem key={item.path} item={item} subNavs={children} />
						})}
				</List>
				{/* {openSidebar && !isDemoUser && (
					<>
						<SidebarProgressBar
							title={'Manager Health'}
							value={parseInt((healthReport.managerHealth.running / healthReport.managerHealth.total) * 100) || 0}
							ValueComponent={() => (
								<Box display={'flex'}>
									<Text ascent='greyBlue' variant='body2'>
										{healthReport.managerHealth.running}
									</Text>
									<Text ascent='greyBlue' variant='body2'>
										/{healthReport.managerHealth.total}
									</Text>
								</Box>
							)}
							rootProps={{
								px: 3,
								mt: 2,
								onMouseEnter: handlePopoverOpen,
								onMouseLeave: handlePopoverClose
							}}
							TooltipComponent={
								<Box
									minWidth={'250px'}
									maxHeight={'400px'}
									overflow={'auto auto'}
									padding={!showMoreHealthInfo ? '1rem 1rem 0.125rem 1rem' : '1rem'}
									className={'managerHealthPopup'}
								>
									<Box
										display={'flex'}
										alignItems={'center'}
										justifyContent={'space-between'}
										className={clsx({
											active: showMoreHealthInfo
										})}
										padding={!showMoreHealthInfo ? 0 : '0 0 1rem 0'}
										margin={!showMoreHealthInfo ? '0 0 0.25rem 0' : '0 0 1rem 0'}
										sx={{
											position: 'relative',

											'&.active:before': {
												content: "''",
												position: 'absolute',
												bottom: '0%',
												left: '50%',
												borderBottom: '1px solid',
												borderBottomColor: (theme) => theme.palette.ascents.washDark,
												width: '70%',
												transform: 'translateX(-50%)'
											}
										}}
									>
										<Box
											textAlign={'center'}
											sx={{
												position: 'relative',
												'&:not(:last-child)': {
													paddingRight: '1.5rem',
													'&:before': {
														content: "''",
														position: 'absolute',
														top: '50%',
														right: 0,
														borderRight: '1px solid',
														borderRightColor: (theme) => theme.palette.ascents.washDark,
														height: '2rem',
														transform: 'translateY(-50%)'
													}
												}
											}}
										>
											<Text>Running</Text>
											<Text fontSize={'1.125rem'} ascent={'green'} fontWeight={'600'}>
												{manageHealthInsights.running}
											</Text>
										</Box>
										<Box
											textAlign={'center'}
											sx={{
												position: 'relative',
												'&:not(:last-child)': {
													paddingRight: '1.5rem',
													'&:before': {
														content: "''",
														position: 'absolute',
														top: '50%',
														right: 0,
														borderRight: '1px solid',
														borderRightColor: (theme) => theme.palette.ascents.washDark,
														height: '2rem',
														transform: 'translateY(-50%)'
													}
												}
											}}
										>
											<Text>Stopped</Text>
											<Text ascent={'red'} fontSize={'1.125rem'} fontWeight={'600'}>
												{manageHealthInsights.stopped}
											</Text>
										</Box>
										<Box
											textAlign={'center'}
											sx={{
												position: 'relative',
												'&:not(:last-child)': {
													paddingRight: '1.5rem',
													'&:before': {
														content: "''",
														position: 'absolute',
														top: '50%',
														right: 0,
														borderRight: '1px solid',
														borderRightColor: (theme) => theme.palette.ascents.washDark,
														height: '2rem',
														transform: 'translateY(-50%)'
													}
												}
											}}
										>
											<Text>Total</Text>
											<Text ascent={'base'} fontSize={'1.125rem'} fontWeight={'600'}>
												{manageHealthInsights.total}
											</Text>
										</Box>
									</Box>

									{!showMoreHealthInfo ? (
										<Box textAlign={'center'}>
											<Button
												ascent={'trans'}
												colorAscent={'textDark'}
												padding={'0.25rem 1rem'}
												fontSize={'12px'}
												onClick={enableMoreDetails}
											>
												<span>See Details</span>
												<Icon fontSize={'1.125rem'} ascent={'textDark'}>
													<ExpandMoreIcon />
												</Icon>
											</Button>
										</Box>
									) : (
										<>
											<Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
												<Text>Modules</Text>
												<Text>Status</Text>
											</Box>
											{Object.entries(healthReport?.managerHealth?.detail || {}).map(([key, value]) => (
												<Box
													key={key}
													display={'flex'}
													alignItems={'center'}
													justifyContent={'space-between'}
													marginTop={'0.75rem'}
												>
													<Text textDecoration={'italic'} ascent={'base'}>
														{key.split('-').pop()}
													</Text>
													<Box
														display={'flex'}
														alignItems={'center'}
														justifyContent={'space-between'}
														minWidth={'4.375rem'}
													>
														<Box
															component={'span'}
															borderRadius={'16px'}
															display={'block'}
															height={'12px'}
															width={'12px'}
															border={'2px solid'}
															marginRight={'0.25rem'}
															borderColor={
																ASCENTS[
																	value === 'running' ? 'green' : value === 'stopped' ? 'red' : 'wash'
																]
															}
														/>
														<Text ascent={'base'}>{value}</Text>
													</Box>
												</Box>
											))}
										</>
									)}
								</Box>
							}
						/>
						<SidebarProgressBar
							rootProps={{
								px: 3,
								mt: 2
							}}
							title={'Database Health'}
							value={parseInt(healthReport.databaseHealth.active_shards_percent_as_number)}
							TooltipComponent={
								<CustomDBHealthTooltip
									activeShards={healthReport.databaseHealth.active_shards}
									unassignedShards={healthReport.databaseHealth.unassigned_shards}
								/>
							}
						/>
					</>
				)} */}
			</Box>

			{openSidebar && (
				<>
					{isDemoUser && <ScheduleACallButton />}

					{isDemoUser && <BuyZeronButton />}

					{/* {showSubsriptionLoader
						? null
						: import.meta.env.VITE_APP_ENABLE_ONPREM !== 'true' &&
						  userRedux.email &&
						  !paidSubscriptionDetails && (
								<Box
									px={1.5}
									py={1.5}
									mx={2}
									my={2}
									display='flex'
									sx={{
										backgroundColor: (theme) => theme.palette.ascents.base,
										border: (theme) => `1px solid ${theme.palette.ascents.lightRed}`,
										borderRadius: '0.5rem'
									}}
									alignItems='center'
								>
									<Box mr={1}>
										<Icon ascent='lightRed' block>
											<ErrorOutlineIcon />
										</Icon>
									</Box>

									<Box>
										<Text fontSize={12} ascent={'washLight'} gutterBottom>
											There is no active subscription!
										</Text>

										<Text
											fontSize={12}
											ascent={'washLight'}
											component={Link}
											textDecoration='underline'
											to={adminRoutesConstant.adminPaths.BillingScreen.url()}
										>
											Subscribe Now
										</Text>
									</Box>
								</Box>
						  )} */}
				</>
			)}

			<UserProfileBar
				openSidebar={openSidebar}
				name={`${userRedux.first_name ? userRedux.first_name : ''} ${userRedux.last_name ? userRedux.last_name : ''}`.trim()}
				avatar={Object.byString(userRedux, 'user.avatar')}
				position={Object.byString(userRedux, 'role.name')}
			/>
		</Drawer>
	)
}

export default Sidebar
