import Loader from '@commons/Loader'
import { Box } from '@mui/material'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const LoadingScreen = () => {
	const token = useSelector((state) => state.auth.token.info)
	const [bgAscent] = useState(token ? 'white' : 'base')
	return (
		<Box
			sx={{
				width: '100%',
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: (theme) => theme.palette.ascents[bgAscent]
			}}
		>
			<Loader />
		</Box>
	)
}

export default LoadingScreen
