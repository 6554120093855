import { AxiosService, MitreAttackService } from '@interceptors/axiosInstance'

export const uploadComplianceDocs = (config) => {
	return AxiosService.post('/rest/v1/compliance/', config)
}

export const updateComplianceDocs = (id, config) => {
	return AxiosService.put(`/rest/v1/compliance/${id}/`, config)
}

export const uploadMandateDocs = (config) => {
	return AxiosService.post('/rest/v1/mandate-document/', config)
}

export const updateMandateDocs = (id, config) => {
	return AxiosService.put(`/rest/v1/mandate-document/${id}/`, config)
}

export const getAllDocs = (config) => {
	return AxiosService.get('/rest/v1/compliance/', config)
}

export const getAllMandateDocs = (config) => {
	return AxiosService.get('/rest/v1/mandate-document/', config)
}

export const deleteDocs = (id, config) => {
	return AxiosService.delete(`/rest/v1/compliance/${id}/`, config)
}

export const existingDocument = (doc, config) => {
	return AxiosService.delete(`/rest/v1/${doc}`, config)
}

export const deleteMandateDocs = (id, config) => {
	return AxiosService.delete(`/rest/v1/mandate-document/${id}/`, config)
}

export const getComplianceScore = (config) => {
	return AxiosService.post('/rest/v1/compliancescore/', config)
}

// export const getAllMandates = config => {
//     return AxiosService.get('/rest/v1/mandate/', config)
// }

export const getAllMandatesControls = (config) => {
	return AxiosService.get('/rest/v1/mandate-controls/', config)
}

export const getAllMandates = (config) => {
	return AxiosService.get('/rest/v1/mandate/', config)
}

export const getFrameworksList = (config) => {
	return AxiosService.get('/rest/v1/compliance-framework/', config)
}

export const getComplianceFrameworkListByType = (type, config) => {
	return AxiosService.get(`/rest/v1/compliance-framework/?type=${type}`, config)
}

export const getControlsByFrameworkId = (config) => {
	return AxiosService.get('/rest/v1/control/', config)
}

export const getMitreDetailsById = (config) => {
	return MitreAttackService.post(
		'/mitre/?fields=name,description,mitigations.name,mitigations.description,mitigations.mitigation_id,mitigations.labels,kill_chain_phases.phase_name',
		config
	)
}

export const getUpcomingSecurityAudit = (config) => {
	return AxiosService.get('/rest/v1/compliance-audit/', config)
}

export const deleteUpcomingSecurityAudit = (id, config) => {
	return AxiosService.delete('/rest/v1/compliance-audit/' + id + '/', config)
}

export const getMandateGap = (config) => {
	return AxiosService.post('/rest/v1/mandate_gap/', config)
}

export const createUpcomingSecurityAudit = (config) => {
	return AxiosService.post('/rest/v1/compliance-audit/', config)
}

export const editUpcomingSecurityAudit = (id, config) => {
	return AxiosService.put('/rest/v1/compliance-audit/' + id + '/', config)
}

export const updateUpcomingSecurityAudit = (config) => {
	return AxiosService.patch('/rest/v1/compliance-audit/', config)
}

export const getAllEvidences = (config) => {
	return AxiosService.get('/rest/v1/evidence-master/', config)
}

export const createEvidence = (config) => {
	return AxiosService.post('/rest/v1/evidence-master/', config)
}

export const updateEvidenceById = (id, config) => {
	return AxiosService.put(`/rest/v1/applicable-evidence/${id}/`, config)
}
//Deleting the Evidence:
export const deleteEvidenceById = (id, config) => {
	return AxiosService.delete(`rest/v1/evidence-master/${id}/`, config)
}
export const getAllImplementedAndUnimplementedControls = (config) => {
	return AxiosService.get('/rest/v1/document_implemented/', config)
}

export const getActiveIntegrationByRecommendedTool = (config) => {
	return AxiosService.get('/rest/v1/active-integration/', config)
}

export const getIntegrationtypemaster = (config) => {
	return AxiosService.get('/rest/v1/integrationtypemaster/', config)
}

export const getEvidenceBadges = (config) => {
	return AxiosService.get('/rest/v1/integrationtypemaster/', config)
}
