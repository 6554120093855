import { AxiosService } from '@interceptors/axiosInstance'

export const getUserSubscriptionDetails = (config) => {
	return AxiosService.get('/payment/v1/subscription/', config)
}

export const getItemDetails = (config) => {
	return AxiosService.get('/payment/v1/item-price/', config)
}

export const renewSubscription = (config) => {
	return AxiosService.get('/payment/v1/renew-subscription/', config)
}

export const buySubscription = (config) => {
	return AxiosService.post('/payment/v1/renew-subscription/', config)
}

export const buyPlanSubscription = (config) => {
	return AxiosService.post('/payment/v1/subscription/', config)
}

export const getAllInvoices = (config) => {
	return AxiosService.get('/payment/v1/invoice/', config)
}

export const payInvoice = (config) => {
	return AxiosService.post('/payment/v1/invoice/', config)
}

export const getHostedUrlForCards = (config) => {
	return AxiosService.get('/payment/v1/payment-source/', config)
}
