import { AxiosService, CrqService, RCVarZeronService } from '../interceptors/axiosInstance'

export const getElasticData = (config) => {
	return AxiosService.post('/rest/v1/search/', config)
}

export const getAgentStatusGraph = (config) => {
	return AxiosService.get('/rest/v1/agent/summary/status/', config)
}

export const getHealthOfServer = (config) => {
	return AxiosService.get('/rest/v1/health/', config)
}

export const getBenchmarkScores = (config) => {
	return AxiosService.get('/rest/v1/benchmark/', config)
}

export const getBreachProbability = (config) => {
	return AxiosService.post('/rest/v1/breachprobability/', config)
}

export const getZScore = (config) => {
	return AxiosService.post('/rest/v1/z-score/', config)
}

export const getCiaTriad = (config) => {
	return AxiosService.post('/rest/v1/ciatriad/', config)
}
export const getCiaTriadByHost = (config) => {
	return AxiosService.post('/rest/v1/ciatriad_by_host/', config)
}

export const getValueAtRisk = (config) => {
	return AxiosService.post('/rest/v1/value_at_risk/', config)
}

export const getAllInitiatives = (id, assigned_to, config) => {
	return AxiosService.get(`rest/v1/initiative/`, config)
}

export const deleteAInitiatives = (id, config) => {
	return AxiosService.delete(`rest/v1/initiative/${id}/`, config)
}

export const getInitiativeById = (id, config) => {
	return AxiosService.get(`/rest/v1/initiative/?initiative_id=${id}`, config)
}

export const createInitiative = (config) => {
	return AxiosService.post('/rest/v1/initiative/', config)
}

export const updateInitiative = (id, config) => {
	return AxiosService.patch(`/rest/v1/initiative/${id}/`, config)
}

export const createTask = (config) => {
	return AxiosService.post('/rest/v1/initiative-task/', config)
}

export const updateTask = (id, config) => {
	return AxiosService.patch(`/rest/v1/initiative-task/${id}/`, config)
}

export const getSimulationResult = (id, config) => {
	return CrqService.get(`/simulation_result?id=abc`, config)
}

export const getScenarioSummary = (id, config) => {
	return CrqService.get(`/scenario_summary?id=abc`, config)
}

export const getScenarioYearlySummary = (id, config) => {
	return RCVarZeronService.get(`/get_year_wise_data`, config)
}

export const getDomainSummary = (id, config) => {
	return CrqService.get(`/domain_summary?id=abc`, config)
}

export const getScenarioDetails = (id, config) => {
	return CrqService.get(`/risk_dashboard?id=abc`, config)
}

export const getRiskApi = (id, config) => {
	return CrqService.get(`/risk_kpi?id=abc`, config)
}

export const getRiskByOs = (config) => {
	return AxiosService.post('/rest/v1/risk-by-os/', config)
}
