import { Close as IconClose } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { ASCENTS } from '@styles/theme'

const SnackbarCloseButton = ({ onClick }) => {
	return (
		<IconButton onClick={onClick}>
			<IconClose sx={{ color: ASCENTS.white }} />
		</IconButton>
	)
}

export default SnackbarCloseButton
