import GlobalStyles from '@mui/material/GlobalStyles'
import { fadeColor, lightenColor } from '@utils/styles'

const useStyles = (theme) => ({
	html: {
		scrollBehavior: 'smooth',
		boxSizing: 'border-box'
	},
	body: {
		// minWidth: "1200px",
		margin: 0,
		padding: 0,
		overflow: 'hidden',
		fontFamily: '"Open Sans", "Roboto", sans-serif',
		'&.offline': {
			filter: 'grayscale(1)'
		}
	},
	'*': {
		boxSizing: 'inherit',
		'&:before': {
			boxSizing: 'inherit'
		},
		'&:after': {
			boxSizing: 'inherit'
		}
	},
	'#root': {
		overflow: 'hidden',
		transition: 'filter 0.25s',
		filter: 'none'
	},

	'.offline-icon': {
		filter: 'grayscale(0)'
	},
	hr: {
		border: 'none !important',
		borderTop: '1px solid !important',
		borderColor: `${theme.palette.ascents.wash} !important`
		// width: "100%",
	},

	'.MuiPaper-rounded': {
		borderRadius: '12px !important'
	},

	'.MuiInputBase-input': {
		// padding: "12px 0.5rem !important",
	},

	'.MuiButton-root': {
		borderRadius: '12px'
	},

	'.MuiAppBar-root': {
		boxShadow: `none !important`
	},

	'.cluster img': {
		width: '100%',
		borderRadius: '50%',
		borderColor: '#433692',
		borderStyle: 'dashed'
	},

	'.MUIDataTableToolbarSelect-root': {
		backgroundColor: 'transparent !important'
	},

	'.code': {
		fontFamily: "'Roboto Mono', monospace !important",
		fontWeight: '500 !important',
		background: '#19133f',
		color: ' #fff !important',
		padding: '1rem 1.5rem',
		borderRadius: '0.5rem',
		fontSize: '13px !important',
		width: '100%',
		cursor: 'pointer',
		display: 'flex',

		'&:before': {
			content: "'$'",
			display: 'inline-block',
			font: 'inherit',
			marginRight: '0.75rem',
			marginTop: '1px'
		},

		'& span': {}
	},

	'.MuiButton-contained': {
		boxShadow: 'none !important'
	},

	'*::-webkit-scrollbar': {
		width: '4px',
		height: '4px'
	},
	'*::-webkit-scrollbar-button': {
		width: '0',
		height: '0'
	},
	'*::-webkit-scrollbar-track': {
		background: fadeColor(theme.palette.ascents.white, 1),
		// background: "transparent",
		border: 'none',
		borderRadius: '1.0625rem',
		'&:hover,&:active': {
			background: fadeColor(theme.palette.ascents.white, 0.1)
		}
	},
	'*::-webkit-scrollbar-track-piece': {},
	'*::-webkit-scrollbar-thumb': {
		// background: fadeColor(theme.palette.ascents.white, 1),
		// background: "transparent",
		background: fadeColor(theme.palette.ascents.primary, 0.85),
		border: 'none',
		borderRadius: '1rem',
		'&:hover,&:active': {
			background: lightenColor(theme.palette.ascents.primary, 0)
		}
	},
	'*::-webkit-scrollbar-corner': {
		background: 'transparent'
	},
	small: {
		color: 'red'
	},
	a: {
		color: theme.palette.ascents.primary,
		textDecoration: 'none',
		cursor: 'pointer !important',
		fontWeight: 600
	},
	InputBase: {
		color: theme.palette.ascents.textLight
	},

	'.MuiButton-contained.Mui-disabled': {
		backgroundColor: 'inherit',
		color: 'inherit',

		'&.MuiButton-containedPrimary': {
			backgroundColor: fadeColor(theme.palette.ascents.primary, 0.8)
			// color: theme.palette.ascents.white,
		}
	},

	'.MuiButton-outlined.Mui-disabled': {
		border: `1px solid ${theme.palette.ascents.textGrey}`,
		color: theme.palette.ascents.textGrey
	},

	// '.MuiTouchRipple-child': {
	//     background: `${theme.palette.ascents.white}`,
	//     opacity: "1 !important",
	// },

	// '.MuiTouchRipple-root': {
	//     opacity: "1 !important",
	// },

	'.MuiTabs-indicator': {},

	'.MuiTooltip-tooltip': {
		fontSize: '0.75rem !important',
		background: `${theme.palette.ascents.white} !important`,
		color: `${theme.palette.ascents.primary} !important`,
		fontWeight: '400 !important',
		border: `none !important`,
		boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2) !important',
		borderRadius: '12px'
	},

	'.MuiTooltip-arrow': {
		color: `${theme.palette.ascents.white} !important`
	},

	'.MuiButton-outlined': {
		backgroundColor: `transparent !important`
		// backgroundColor: `${theme.palette.ascents.white} !important`,
	},
	'.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation': {
		zIndex: 99999
	},
	'input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active, input:-internal-autofill-selected, input:-internal-autofill-previewed':
		{
			'-webkit-box-shadow': '0 0 0 0px rgba(255,255,255,0) inset !important',
			backgroundColor: 'rgba(255,255,255,0) !important',
			animation: 'none !important',
			transitionDelay: '999999s !important'
		},

	'.editor-preview-full': {
		'& img': {
			maxWidth: '80% !important',
			display: 'block !important'
		}
	},
	'.EasyMDEContainer .CodeMirror': {
		fontSize: '0.875rem'
	},

	'.MuiIconButton-root': {
		padding: 8
	},

	'.MuiIconButton-root:disabled': {
		opacity: 0.35
	},

	'.react-loader-spinner': {
		'& svg': {
			height: '100%',
			display: 'block'
		}
	},
	'.loginForm': {
		'& .MuiInputAdornment-root': {
			background: '#19133f',
			display: 'block',
			position: 'absolute',
			right: 0,
			top: '50%',
			transform: 'translateY(-50%)',
			height: 'auto',
			padding: '0 0.25rem',

			'& .MuiSvgIcon-root': {
				verticalAlign: 'middle'
			}
		}
	},
	'.MuiBackdrop-root': {
		backgroundColor: fadeColor(theme.palette.ascents.base, 0.65)
	},
	'.blur-it': {
		filter: 'blur(1.5px) !important'
	},
	'.blur-children': {
		'& *': {
			filter: 'blur(1.5px)'
		},
		'& .noblur': {
			filter: 'blur(0px) !important',
			'& *': {
				filter: 'blur(0px) !important'
			}
		}
	},
	'.ql-container': {
		minHeight: '110px !important'
		// maxHeight: "300px !important"
	},
	'.ql-editor': {
		maxHeight: 'inherit !important'
	},

	canvas: {
		boxSizing: 'initial !important'
	},

	'.hide': {
		display: 'none'
	},

	'.hide-onprint': {
		'@media print': {
			display: 'none'
		}
	},

	'.show-onprint': {
		'@media print': {
			display: 'block'
		}
	},

	'.pswp__bg': {
		background: `${theme.palette.ascents.wash} !important`
	},
	'.pswp__top-bar': {
		background: `${theme.palette.ascents.base} !important`
	},
	'.pswp img': {
		objectFit: 'contain'
	},
	'img.gallery-img': {
		maxWidth: '100%',
		display: 'block',
		borderRadius: '0.5rem'
	},

	'.pswp__button--arrow--left::before, .pswp__button--arrow--right::before': {
		backgroundColor: `${theme.palette.ascents.base} !important`
	},

	'.no-events-children': {
		'& *': {
			pointerEvents: 'none'
		}
	},
	p: {
		margin: 0
	},
	'body > iframe': {
		// WARNING: In dev mode, react-scripts ERROR display causes blank iframe
		// TODO: REMOVE when error gets solved
		display: import.meta.env.NODE_ENV === 'development' ? 'none !important' : 'none !important'
	},
	'.public-DraftEditor-content': {
		maxHeight: '150px',
		minHeight: '100px',
		overflow: 'auto',
		fontSize: '0.875rem',
		fontWeight: 'normal'
	},
	'.draft-rte-wrapper': {
		padding: '0.5rem',
		border: `1px solid ${theme.palette.ascents.primary}`,
		borderRadius: '0.5rem'
	},
	'.draft-js-seperator': {
		borderRight: `1px solid ${theme.palette.ascents.greyBlue}`,
		margin: '0.375rem 0.75rem'
	},
	'.public-DraftEditorPlaceholder-root': {
		opacity: 0.5,
		pointerEvents: 'none',
		height: 0
	},
	'.img-container': {
		position: 'relative',
		'& img': {
			position: 'absolute',
			display: 'block',
			width: '100%',
			height: '100%',
			objectFit: 'cover'
		}
	},
	'.swiper': {
		height: '100%',
		'&-button-next, &-button-prev': {
			color: theme.palette.ascents.primary,
			'&:after': {
				fontSize: '16px',
				fontWeight: 600
			}
		}
	},
	'.MuiDrawer-modal': {
		zIndex: '9999 !important'
	},
	'.MuiPopover-root': {
		zIndex: '999999 !important'
	},
	'.infinite-scroll-component': {
		overflowX: 'hidden !important'
	},
	'input::-webkit-inner-spin-button': {
		display: 'none'
	},
	'.notistack-MuiContent': {
		fontFamily: '"Open Sans", "Roboto", sans-serif'
	},
	'.MuiTooltip-popper': {
		zIndex: '9999 !important'
	},
	'.notistack-SnackbarContainer': {
		zIndex: '99999 !important'
	}
})

const CssBaseline = () => {
	return <GlobalStyles styles={useStyles} />
}

export default CssBaseline
