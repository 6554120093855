import { refreshUserData } from '@redux/auth/reducers'
import { updateUserDetails } from '@services/authService'
import theme from '@styles/theme'
import React from 'react'
import Joyride, { STATUS } from 'react-joyride'
import { useDispatch, useSelector } from 'react-redux'

const InitialTutorial = () => {
	const user = useSelector((state) => state.auth.user.info)
	const [scrollEnabledIndexes] = React.useState([7])
	const [scrollIsEnabled, setScrollIsEnabled] = React.useState(true)
	const dispatch = useDispatch()

	const enableScroll = () => setScrollIsEnabled(true)
	const disableScroll = () => setScrollIsEnabled(false)

	const handleJoyrideCallback = (data) => {
		const { index, status } = data

		if (scrollEnabledIndexes.includes(index)) {
			enableScroll()
		} else {
			disableScroll()
		}

		if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
			// update redux
			const { first_name, last_name, username, email } = user.user || {}

			const request = updateUserDetails()

			request
				.api({
					data: {
						first_name,
						last_name,
						username,
						email,
						taken_walkthrough: true
					}
				})
				.then(() => {
					dispatch(refreshUserData())
				})
		}
	}

	const [steps] = React.useState([
		{
			target: '#sidebar',
			content: "In this sidebar you'll find all the navigation links based upon your role.",
			placementBeacon: 'right',
			placement: 'right',
			disableBeacon: true
		},
		{
			target: '#dateRangeDropdown',
			content: 'This is the date range dropdown.',
			placementBeacon: 'left',
			placement: 'left',
			disableBeacon: true
		},
		{
			target: '#refreshButton',
			content: 'Click this button to refresh the entire application without reloading the page.',
			placementBeacon: 'left',
			placement: 'left',
			disableBeacon: true
		},
		{
			target: '#notificationButton',
			content: 'All notifications that you receive will be displayed over here.',
			placementBeacon: 'left',
			placement: 'left',
			disableBeacon: true
		},
		{
			target: '#introspectOverviewCard',
			content: 'This is the basic overview of your introspection details.',
			placementBeacon: 'bottom',
			placement: 'bottom',
			disableBeacon: true
		},
		{
			target: '#dashboardHighlightCard',
			content: 'All highlights of your account will be displayed here.',
			placementBeacon: 'bottom',
			placement: 'bottom',
			disableBeacon: true
		},
		{
			target: '#dashboardTopVulnerablePoints',
			content: 'These are the top vulnerable points we found in your application',
			placementBeacon: 'bottom',
			placement: 'bottom',
			disableBeacon: true
		},
		{
			target: '#dashboardSecurityAlerts',
			content: 'All the security alerts will be displayed over here.',
			placementBeacon: 'bottom',
			placement: 'bottom',
			disableBeacon: true
		},
		{
			target: '#dashboardBreachProbability',
			content: 'This is the probability of how much breach has occured in your applications.',
			placementBeacon: 'bottom',
			placement: 'bottom',
			disableBeacon: true
		},
		{
			target: '#dashboardComplianceScore',
			content: 'This is the complicance score for your applications.',
			placementBeacon: 'bottom',
			placement: 'bottom',
			disableBeacon: true
		},
		{
			target: '#dashboardZScore',
			content: 'This is the Z score for your applications.',
			placementBeacon: 'bottom',
			placement: 'bottom',
			disableBeacon: true
		}
	])

	return (
		<>
			<Joyride
				continuous
				showProgress
				showSkipButton
				run={!!user.user && !user.user.taken_walkthrough}
				disableScrolling={!scrollIsEnabled}
				callback={handleJoyrideCallback}
				spotlightClicks
				steps={steps}
				floaterProps={{
					styles: {
						wrapper: {
							zIndex: 1000000000
						}
					}
				}}
				styles={{
					options: {
						zIndex: 10000000000,
						primaryColor: theme.palette.ascents.primary,
						textColor: theme.palette.ascents.black
					}
				}}
			/>
		</>
	)
}

export default InitialTutorial
