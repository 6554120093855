import { useSelector } from 'react-redux'
import RedirectRoute from './RedirectRoute'

const IndexRoute = () => {
	const token = useSelector((state) => state.auth.token.info)
	console.log('🚀 ~ file: IndexRoute.js:6 ~ IndexRoute ~ token:', token)

	console.log('BROOOO')

	return <RedirectRoute routeKey={token ? 'DashboardScreen' : 'LoginScreen'} />
}

export default IndexRoute
