import { ReactComponent as LogoSmSvg } from '@assets/images/logo2.svg'
import { ReactComponent as LogoSmSvgWhite } from '@assets/images/logo2white.svg'
import { ReactComponent as LogoBlack } from '@assets/images/logoBlack.svg'
import { ReactComponent as LogoSidebar } from '@assets/images/logoSidebarWhite.svg'
import { ReactComponent as LogoPrimary } from '@assets/images/logo_primary.svg'
import { ReactComponent as LogoFullSvg } from '@assets/images/logofull.svg'
import { ReactComponent as LogoFullSvgWhite2 } from '@assets/images/zeron-logo-white.svg'
import { Box } from '@mui/material'
import Grow from '@mui/material/Grow'
import React from 'react'

const Logo = ({
	width = '10rem',
	height = 'auto',
	logoType = 'full_white',
	borderBottomWidth = '1px',
	logoWrapperProps,
	logoSubTextComponent
}) => {
	const [rendered, setRendered] = React.useState(false)

	const logoTypes = {
		full_white: <LogoFullSvgWhite2 />,
		full: <LogoFullSvg />,
		small: <LogoSmSvg />,
		small_white: <LogoSmSvgWhite />,
		logo_black: <LogoBlack />,
		sidebar: <LogoSidebar />,
		logo_primary: <LogoPrimary />
	}

	React.useEffect(() => {
		if (!rendered) {
			setRendered(true)
		}
	}, [])

	return (
		<Grow in={rendered} style={{ transformOrigin: '0 1 0' }} {...(rendered ? { timeout: 750 } : {})}>
			<Box
				// className={classes.root}
				paddingBottom={2.5}
				{...logoWrapperProps}
				sx={{
					position: 'relative',
					margin: '1.5rem 1.5rem 0.25rem 1.5rem',
					textAlign: 'center',
					borderBottom: (theme) => `${borderBottomWidth} solid ${theme.palette.ascents.baseLow}`,
					...logoWrapperProps?.sx
				}}
			>
				<Box
					sx={{
						height,
						width,
						objectFit: 'contain',
						display: 'inline-block',

						'& svg': {
							width: '100%',
							height: '100%'
						}
					}}
					// className={classes.img}
				>
					{logoTypes[logoType]}
				</Box>

				{!!logoSubTextComponent && <Box mt={2}>{logoSubTextComponent}</Box>}
			</Box>
		</Grow>
	)
}

export default Logo
