import { Typography } from '@mui/material'
import { memo } from 'react'

// eslint-disable-next-line react/display-name
const Text = memo(
	({
		ascent = 'textDark',
		className,
		children,
		margin,
		padding,
		float,
		width,
		isCustomColor = false,
		textDecoration,
		noWrap,
		lineBreak,
		direction,
		// lineLimit = -1,
		cursor,
		wordBreak,
		sx,
		...props
	}) => {
		return (
			<Typography
				noWrap={noWrap}
				{...props}
				sx={{
					color: (theme) => `${isCustomColor ? ascent : theme.palette.ascents[ascent]} !important`,
					padding,
					margin,
					float,
					width,
					textDecoration,
					lineBreak,
					direction,
					cursor,
					wordBreak,
					...sx
				}}
				className={className}
			>
				{children}
			</Typography>
		)
	}
)

Text.propTypes = {}

export default Text
