import { colorManipulator } from '@utils/styles'

const responsive = {
	responsive: true,
	maintainAspectRatio: false
}

const noGridLines = {
	scales: {
		xAxes: [
			{
				gridLines: {
					display: false
				}
			}
		],
		yAxes: [
			{
				gridLines: {
					display: false
				}
			}
		]
	}
}

const noLabels = {
	responsive: true,
	maintainAspectRatio: false,
	scales: {
		xAxes: [
			{
				gridLines: {
					display: false
				},
				ticks: {
					display: false //this will remove only the label
				}
			}
		],
		yAxes: [
			{
				gridLines: {
					display: false
				},
				ticks: {
					display: false //this will remove only the label
				}
			}
		]
	}
}

const noLegends = {
	legend: {
		display: false
	}
}

const rightDottedLegend = {
	legend: {
		position: 'right',
		labels: {
			usePointStyle: true,
			boxWidth: 6
		}
	}
}

export const chartTypes = {
	BAR: 'bar',
	GROUP_BAR: 'group_bar',
	LINE: 'line',
	PIE: 'pie',
	DOUGHNUT: 'doughnut',
	DOUGHNUT2: 'doughnut2',
	RADAR: 'radar',
	SCATTER: 'scatter',
	BUBBLE: 'bubble',
	SANKEY: 'sankey'
}
export const chartTimeAxis = {
	type: 'time',
	ticks: {
		maxTicksLimit: 5,
		// autoSkip: false,
		maxRotation: 0
		// minRotation: 0,
	},

	time: {
		unit: 'hour',
		// stepSize: 4,

		displayFormats: {
			minute: 'DD/MM hh:mm',
			hour: 'DD/MM hh:mm'
		}
	}
}

export const labelToggleOptions = {
	...responsive,
	...noLegends,
	...noLabels,
	scale: {
		pointLabels: {
			fontSize: 0
		},
		gridLines: {
			circular: true
		},
		ticks: {
			display: false,
			maxTicksLimit: 6
		}
	}
}

export const hideTooltip = {
	tooltips: {
		enabled: false
	}
}

// ? Note: unit,displayAs will be used in moment().
export const timeFilters = {
	// In Secs
	intervals: [
		{
			value: 2,
			unit: 'seconds',
			displayAs: 'seconds'
		},
		{
			value: 10,
			unit: 'seconds',
			displayAs: 'seconds'
		},
		{
			value: 30,
			unit: 'seconds',
			displayAs: 'seconds'
		}
	],
	fixed: [
		{
			value: 2,
			unit: 'hour',
			displayAs: 'hour'
		},
		{
			value: 24,
			unit: 'hour',
			displayAs: 'hour'
		},
		{
			value: 96,
			unit: 'hour',
			displayAs: 'days'
		},
		{
			value: 168,
			unit: 'hour',
			displayAs: 'days'
		}
	]
}

export const preHideDatasetsPlugin = {
	afterInit: async (chart) => {
		// If `hiddenSlices` has been set.
		if (typeof chart.config.data.hiddenSlices !== 'undefined' && chart.config.data.hiddenSlices.length > 0) {
			const { type } = chart.config

			let meta

			if (type === 'pie' || type === 'doughnut') {
				// Iterate all datasets.

				for (var i = 0; i < chart.data.datasets.length; ++i) {
					// Iterate all indices of slices to be hidden.

					for (let j = 0; j < chart.config.data.hiddenSlices.length; j++) {
						const element = chart.config.data.hiddenSlices[j]
						meta = await chart.getDatasetMeta(i)

						// toggle visibility of index if exists
						if (meta.data[element]) {
							meta.data[element].hidden = true
						}
					}
				}

				// !Sometimes getting canvas as null, which causes crash :(
				if (chart.canvas) {
					chart.update()
				}
			} else {
				for (let j = 0; j < chart.config.data.hiddenSlices.length; j++) {
					const element = chart.config.data.hiddenSlices[j]

					if (!!element || element === 0) {
						meta = await chart.getDatasetMeta(element)

						meta.hidden = true
					}
				}

				// !Sometimes getting canvas as null, which causes crash
				if (chart.canvas) {
					chart.update()
				}
			}
		}
	}
}

export const getRandomColor = () => colorManipulator('#' + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, '0'))

export const colorsForCharts = [
	// "#4700ff",
	// "#9d02d7",
	// "#cd34b5",
	// "#ea5f94",
	// "#fa8775",
	// "#ffb14e",
	// "#ffd700",
]
	.map((c) => colorManipulator(c))
	.concat([
		// colorManipulator("#8c3892"),
		// colorManipulator("#c04088"),
		// colorManipulator("#e75779"),
		// colorManipulator("#ff796b"),
		// colorManipulator("#ffa161"),
		// colorManipulator("#ffca64"),
		// colorManipulator("#fff37a"),

		// colorManipulator("#250FB0"),

		// colorManipulator("#363CCC"),
		// colorManipulator("#2EC1AC"),
		// colorManipulator("#FF00C3"),
		// colorManipulator("#FF884B"),
		// colorManipulator("#00DD75"),
		// colorManipulator("#F0AF64"),
		// colorManipulator("#FF616D"),
		// colorManipulator("#0090A8"),
		// colorManipulator("#E9738E"),
		// colorManipulator("#CDF181"),
		// colorManipulator("#89B9E1"),
		// colorManipulator("#FF00C3"),

		colorManipulator('#6946ff'),
		colorManipulator('#23b5ad'),
		// colorManipulator("#84bb1f"),
		// colorManipulator("#f09037"),
		// colorManipulator("#c11c20"),
		colorManipulator('#08007b'),
		colorManipulator('#3c5bf5'),
		colorManipulator('#433692'),
		colorManipulator('#ADBAE3'),
		colorManipulator('#E9897E'),
		colorManipulator('#637D74')
	])

export const colorsByStatus = {
	ACTIVE: colorManipulator('#2EC1AC'),
	DISCONNECTED: colorManipulator('#f54242'),
	NEVER_CONNECTED: colorManipulator('#BCC1DD')
}

export const intervalTypes = {
	SECOND: {
		name: 's',
		unit: 1000
	},
	MINUTE: {
		name: 'm',
		unit: 60 * 1000
	},
	HOUR: {
		name: 'h',
		unit: 60 * 60 * 1000
	},
	DAY: {
		name: 'd',
		unit: 24 * 60 * 60 * 1000
	},
	MONTH: {
		name: 'M',
		unit: 30 * 24 * 60 * 60 * 1000
	},
	YEAR: {
		name: 'y',
		unit: 365 * 24 * 60 * 60 * 1000
	}
}
export const severityColors = {
	CRITICAL: '#ff5564',
	LOW: '#2EC1AC',
	MEDIUM: '#fa9f00'
}
export const severityLevels = {
	CRITICAL: {
		label: 'critical',
		id: 'CRITICAL',
		color: severityColors.CRITICAL,
		greaterThan: 10,
		lessThanOrEqual: 15
	},
	LOW: {
		label: 'medium',
		id: 'MEDIUM',
		color: severityColors.MEDIUM,
		greaterThan: 5,
		lessThanOrEqual: 10
	},
	MEDIUM: {
		label: 'low',
		id: 'LOW',
		color: severityColors.LOW,
		greaterThan: 0,
		lessThanOrEqual: 5
	}
}
export const chartOptions = {
	noLabels,
	noLegends,
	responsive,
	noGridLines,
	labelToggleOptions,
	rightDottedLegend,
	chartTimeAxis,
	hideTooltip,
	colorsForCharts,
	preHideDatasetsPlugin
}
