//VRM Screens API here::
import { DrmService, VRMService } from '@interceptors/axiosInstance'

//Register Page APIS:
//1. SignUp api::
export const SignUp = (config) => {
	return VRMService.post('/api/v1/auth/signup', config)
}
//2. Verify Register OTP:
export const VerifyRegisterOTP = (config) => {
	return VRMService.post('/api/v1/auth/verify-otp', config)
}
//3. SetPassword for Register:
export const SetPasswordRegister = (config) => {
	return VRMService.post('/api/v1/auth/set-password', config)
}
//4. resend otp for signup:
export const resendOTP = (config) => {
	return VRMService.post('/api/v1/auth/resend-signup-otp', config)
}
//5. Show all plans::
export const showAllPlans = (config) => {
	return VRMService.get('/api/v1/billing/get-all-plans', config)
}
//6. GetLink from Plan Code:::
export const getLinkByPlan = (plan, config) => {
	return VRMService.post(`/api/v1/billing/create-customer-subscription/${plan}`, config)
}
//7. Get Subbscription API::
export const getSubscription = (config) => {
	return VRMService.get('/api/v1/billing/get-subscription-details', config)
}
//8. Download VRM Report::
export const getVRMReport = (assessmentID, config) => {
	return VRMService.get(`/api/v1/assessment/download-vendor-assessment-report/${assessmentID}`, config)
}

//MFA issues:

export const configureNewMfa = (config) => {
	return VRMService.post('/api/v1/auth/otp-mfa/generate', config)
}

//Vendor APIs:
//1. get all vendor list
export const GetVendor = (config) => {
	return VRMService.get('api/v1/vendor/get-all-vendors', config)
}
//2. Add Vendor
export const AddVendor = (config) => {
	return VRMService.post('api/v1/vendor/add-vendor/', config)
}
//3. Edit Vendor
export const EditVendor = (id, config) => {
	return VRMService.put(`api/v1/vendor/update-vendor/${id}`, config)
}
//4. Get Vendor info by ID
export const GetVendorListByID = (id, config) => {
	return VRMService.get(`api/v1/vendor/get-vendor/${id}`, config)
}

export const GetVendorLink = (vendorId, config) => {
	return VRMService.get(`api/v1/vendor/get-assessment-link/${vendorId}`, config)
}

//Onboard Vendor
export const OnboardVendor = (vendor_id, config) => {
	return VRMService.post(`api/v1/vendor/onboard-verdor/ven_id=${vendor_id}`, config)
}
//Get Vendor Assesment info by ID
export const getVendorAssessmentData = (id, config) => {
	return VRMService.get(`api/v1/vendor/get-vendor-assessment/${id}`, config)
}

//Get all steps in vendor process
export const getVendorSteps = (id, config) => {
	return VRMService.get(`api/v1/vendor/get-vendor-assessment/${id}`, config)
}

//Master Feed
//1. Get Services API
export const GetServices = (config) => {
	return VRMService.get('api/v1/master/get-all-services/', config)
}
//2. Get all services
export const getAllRM = (config) => {
	return VRMService.get('api/v1/user/risk-manager/get-all', config)
}
// Get all Roles
export const getAllRoles = (config) => {
	return VRMService.get('api/v1/user/get-all-roles', config)
}

//Get Crtiticality Matrix Data
export const getCrtiticalityMatrix = (config) => {
	return VRMService.get(`api/v1/builder/get-criticality-matrix`, config)
}
//Update Crtiticality Matrix
export const updateCriticalitMatrix = (config) => {
	return VRMService.put('/api/v1/builder/update-criticality-matrix', config)
}
// Get role by ID:
export const getRoleByID = (id, config) => {
	return VRMService.get(`api/v1/user/role/${id}`, config)
}

//Update Role
export const updateRoles = (id, config) => {
	return VRMService.put(`api/v1/user/update-role/${id}`, config)
}

//5. Add Risk Manager
export const inviteUser = (config) => {
	return VRMService.post('api/v1/user/invite-user', config)
}
// 6. View UserData
export const getUserDataID = (id, config) => {
	return VRMService.get(`api/v1/user/user/${id}`, config)
}
//7. Get All permissions
export const GetPermissions = (config) => {
	return VRMService.get(`api/v1/user/me`, config)
}
//Update User Details:
export const updateUserDetails = (config) => {
	return VRMService.put('/api/v1/user/me/', config)
}
//8. Update User Details
export const updateUserAPI = (id, config) => {
	return VRMService.put(`api/v1/user/update-user/${id}`, config)
}
//9. Get Template ID
export const getTemplate = (config) => {
	return VRMService.get(`api/v1/organisation/get-all-templates`, config)
}

//Update Template
export const updateTemplate = (id, config) => {
	return VRMService.put(`api/v1/builder/update-template/${id}`, config)
}

//Update Steps and Forms
export const updateStepsForms = (config) => {
	return VRMService.put(`api/v1/builder/update-template-step-form-data`, config)
}

//Add Steps and Forms
export const addStepsForms = (config) => {
	return VRMService.post(`api/v1/builder/create-step-and-forms`, config)
}

//Delete Template
export const deleteTemplate = (id, config) => {
	return VRMService.delete(`api/v1/builder/delete-template/${id}`, config)
}

//10. Get Domains
export const getDomain = (config) => {
	return VRMService.get(`api/v1/master/get-all-domains/`, config)
}
// Create Role
export const createRole = (config) => {
	return VRMService.post('api/v1/user/create-role', config)
}
//Create Services
export const AddService = (config) => {
	return VRMService.post('api/v1/master/create-service', config)
}
//Get Service by ID
export const GetServiceByID = (id, config) => {
	return VRMService.get(`api/v1/master/get-service/${id}`, config)
}
//Get Domain by ID
export const GetDomainByID = (id, config) => {
	return VRMService.get(`api/v1/master/get-domain/${id}`, config)
}
//Get Subdomain by ID
export const GetSubDomainByID = (id, config) => {
	return VRMService.get(`api/v1/master/get-subdomain/${id}`, config)
}
//Update Service
export const UpdateServices = (id, config) => {
	return VRMService.put(`api/v1/master/update-service/${id}`, config)
}
//Delete Service
export const DeleteService = (id, config) => {
	return VRMService.delete(`api/v1/master/delete-service/${id}`, config)
}
//Get all Subdomains
export const getSubDomain = (config) => {
	return VRMService.get(`api/v1/master/get-all-subdomain`, config)
}

//Get All Risk Manager
export const getAllRiskManager = (config) => {
	return VRMService.get(`api/v1/user/risk-manager/get-all`, config)
}

//Create SubDomain
export const CreateSubDomain = (config) => {
	return VRMService.post('api/v1/master/create-subdomain', config)
}
//Update Domain
export const UpdateDomain = (id, config) => {
	return VRMService.put(`api/v1/master/update-domain/${id}`, config)
}
//Create Domain
export const CreateDomain = (config) => {
	return VRMService.post('api/v1/master/create-domain', config)
}
//Update SubDomain
export const UpdateSubDomain = (id, config) => {
	return VRMService.put(`api/v1/master/update-subdomain/${id}`, config)
}
//Delete Subdomain
export const DeleteSubDomain = (id, config) => {
	return VRMService.delete(`api/v1/master/delete-subdomain/${id}`, config)
}
//Delete Subdomain
export const DeleteDomain = (id, config) => {
	return VRMService.delete(`api/v1/master/delete-domain/${id}`, config)
}
//Delete Role
export const DeleteRole = (id, config) => {
	return VRMService.delete(`api/v1/user/delete-role/${id}`, config)
}

//Create DomainSubDomain
export const CreateDomainSubdomain = (config) => {
	return VRMService.post('api/v1/master/create-domain-subdomain', config)
}
//Update DomainSubDomain
export const UpdateDomainSubDomain = (config) => {
	return VRMService.put(`api/v1/master/update-domain-subdomain/`, config)
}

//Vendor Screen
export const GetVendorSections = (assessment_id, vendor_id, config) => {
	return VRMService.get(`api/v1/assessment/get-vendor-form-section/${assessment_id}/${vendor_id}`, config)
}
export const GetVendorQuestions = (assessment_id, vendor_id, config) => {
	return VRMService.get(`api/v1/assessment/get-vendor-question/${assessment_id}/${vendor_id}`, config)
}

export const GetMyQuestions = (assessment_id, form_id, config) => {
	return VRMService.get(`/api/v1/assessment/get-my-question/${assessment_id}/${form_id}`, config)
}

export const GetVendorOptions = (vendor_id, config) => {
	return VRMService.get(`api/v1/vendor/get-vendor-services/${vendor_id}`, config)
}

export const SaveMasterDataRadio = (config) => {
	return VRMService.post(`api/v1/assessment/save-answer-data`, config)
}

export const UploadFile = (vendor_id, config) => {
	return VRMService.post(`api/v1/file/uploadfile/${vendor_id}`, config)
}
//Uppload File for Vendor and RM form
export const UploadFile2 = (assessment_id, config) => {
	return VRMService.post(`api/v1/file/uploadfile2/${assessment_id}`, config)
}
//Vendor Form Submit API::
export const FormSubmit = (form_id, config) => {
	return VRMService.post(`api/v1/assessment/save-all-answer/${form_id}`, config)
}
//RM Form Submit :::
export const RMFormSubmit = (form_id, config) => {
	return VRMService.post(`/api/v1/assessment/save-my-form-answer/${form_id}`, config)
}
// Vendor Form SKip API::
export const FormSkipSubmit = (assessment_id, form_id, config) => {
	return VRMService.post(`/api/v1/assessment/skip-form/${assessment_id}/${form_id}`, config)
}
export const FormSubmitReview = (form_id, config) => {
	return VRMService.post(`api/v1/assessment/save-review/${form_id}`, config)
}

//Send Mail through RM:::
export const CustomMailTrigger = (config) => {
	return VRMService.post('/api/v1/assessment/send-form-to-custom-email', config)
}

export const getOrgForm = (vendorID, formID, config) => {
	return VRMService.get(`/api/v1/assessment/get-org-question/${vendorID}/${formID}`, config)
}
export const getCompletedStatus = (vendorID, config) => {
	return VRMService.get(`/api/v1/assessment/completed-steps-and-forms/${vendorID}`, config)
}
export const getManageVendorSteps = (id, config) => {
	return VRMService.get(`/api/v1/assessment/all-step-and-form/${id}`, config)
}

export const getLogoUpload = (config) => {
	return VRMService.post('/api/v1/file/upload_logo/', config)
}

//Add Builder Template
export const saveQuestions = (config) => {
	return VRMService.post('/api/v1/builder/create-question-and-option-data/', config)
}

//Dashboard APIS

//Process Criticality
export const processCriticalityGraph = (formID, vendorID, config) => {
	return VRMService.get(`/api/v1/assessment/criticality/vendor_id=${vendorID}/graph_type=${formID}`, config)
}

//Process Risk Score
export const allGraphData = (config) => {
	return VRMService.post('/api/v1/kpi/vendor-form-based-kpi', config)
}

export const DomainWiseScoreData = (vendorID, assessmentID, scoreID, config) => {
	return VRMService.get(`/api/v1/assessment/get-vendor-domain-score/${vendorID}/${assessmentID}/${scoreID}`, config)
}
export const OverallComplianceScoreData = (vendorID, assessmentID, config) => {
	return VRMService.get(`/api/v1/assessment/get-vendor-overall-compliance/${vendorID}/${assessmentID}`, config)
}
// Domain wise score
export const DomainWiseScoreDashboard = (scoreID, config) => {
	return VRMService.get(`/api/v1/assessment/get-domain-score/${scoreID}`, config)
}

//Get Questions Table
export const getQuestionTable = (templateID, formID, config) => {
	return VRMService.get(`/api/v1/builder/get-form-question/temp_id=${templateID}/form_id=${formID}`, config)
}

//Get Question
export const getQuestionData = (id, config) => {
	return VRMService.get(`/api/v1/builder/get-question/${id}`, config)
}
//Edit Question
export const editQuestionData = (id, config) => {
	return VRMService.put(`/api/v1/builder/update-question/${id}`, config)
}
//Delete Question
export const terminateQuestion = (id, config) => {
	return VRMService.delete(`/api/v1/builder/delete-question/${id}`, config)
}
//De- Link Question
export const unLinkQuestion = (config) => {
	return VRMService.post(`/api/v1/builder/unlink-form-question`, config)
}
//All Risks in Vendor
export const riskByVendor = (id, config) => {
	return VRMService.get(`/api/v1/vendor/get-risk/ven_id=${id}`, config)
}
//Add Risk in Risk Monitoring
export const addRisks = (config) => {
	return VRMService.post('/api/v1/vendor/add-risk/', config)
}
//Edit risk Monitoring
export const editRisks = (id, config) => {
	return VRMService.put(`/api/v1/vendor/update-risk/${id}`, config)
}
//Termination Questions Check
export const terminationCheck = (id, config) => {
	return VRMService.get(`/api/v1/assessment/get-vendor-termination-checks/temp_id=${id}`, config)
}
//Terminate Vendor
export const terminateVendor = (config) => {
	return VRMService.post('/api/v1/assessment/terminate-vendor', config)
}

export const resetPassword = (config) => {
	return VRMService.post('/api/v1/auth/forgot-password', { checkToken: false, ...config })
}
//Forget Password Submit OTP:
export const submmitForgetOTP = (config) => {
	return VRMService.post('/api/v1/auth/verify-reset-otp', config)
}
//Process Vrm all steps
export const getAllVRMSteps = (id, config) => {
	return VRMService.get(`/api/v1/builder/get-all-form-and-step/temp_id=${id}`, config)
}
//Get QuestionBank Questions:
export const getQuestionBankQuestions = (id, config) => {
	return VRMService.get(`/api/v1/builder/get-all-question/unlink=${id}`, config)
}
//Link Question to Form
export const LinkQuestionToForm = (config) => {
	return VRMService.put('/api/v1/builder/update-ques-form-template/', config)
}
//Create Template
export const createTemplate = (config) => {
	return VRMService.post('/api/v1/builder/create-template-step-form/', config)
}
//Get Criticality Matrix
// export const GetCriticalityMatrix = (config) => {
// 	return VRMService.get('/api/v1/builder/get-all-criticality-matrix', config)
// }
//Email Trigger to Vendor
export const TriggerEmailVendor = (config) => {
	return VRMService.post('/api/v1/vendor/send-mail-to-vendor', config)
}

//Vendor Termination get all questions:
export const GetVendorTerminateAllQuestions = (id, config) => {
	return VRMService.get(`/api/v1/assessment/get-vendor-termination-checks/temp_id=${id}`, config)
}
//Create Terminate Vendor Question::
export const CreateVendorTerminateQuestions = (config) => {
	return VRMService.post('/api/v1/assessment/set-termination-question', config)
}
// Update Termination Question
export const UpdateVendorTerminateQuestions = (id, config) => {
	return VRMService.put(`/api/v1/assessment/update-termination-question/${id}`, config)
}
// Delete Termination Question
export const DeleteVendorTerminateQuestions = (id, config) => {
	return VRMService.delete(`/api/v1/assessment/delete-termination-question/${id}`, config)
}

//DRM Service
export const RiskCategorization = (uuid, config) => {
	return DrmService.get(`scan/timeline/${uuid}/risk`, config)
}
export const EPSSDataCategorization = (uuid, config) => {
	return DrmService.get(`scan/timeline/${uuid}/epss`, config)
}

export const ScanStatus = (uuid, venId, orgName, config) => {
	return DrmService.get(`scan/status/${uuid}/org=${orgName}/ven_id=${venId}`, config)
}

export const OrgRiskScoreData = (uuid, config) => {
	return DrmService.get(`scan/timeline/${uuid}/risk_score`, config)
}
//Upload Document API for Checklist
export const uploadCheckList = (config) => {
	return VRMService.post('/api/v1/master/get-checklist/', config)
}

export const getCriticalityMatrix = (config) => {
	return VRMService.get('/api/v1/kpi/criticality-matrix-mapping', config)
}

export const updateQuestionsBank = (config) => {
	return VRMService.put('/api/v1/builder/update-question-and-option-data', config)
}

//Collab Email Trigger API:
export const triggerCollabEmail = (config) => {
	return VRMService.post('/api/v1/assessment/send-mail-to-collaborator', config)
}

//Assignment Vendor API v2

export const getQuestionAuto = (config) => {
	return VRMService.get('/api/v1/assessment_v2/all-question-vendor-auto', config)
}
export const getQuestionManual = (config) => {
	return VRMService.get('/api/v1/assessment_v2/all-question-vendor-manual', config)
}
export const sendMailCollaboratorRM = (config) => {
	return VRMService.post('/api/v1/assessment/send-form-to-custom-email', config)
}
export const sendMailCollaborator = (config) => {
	return VRMService.post('/api/v1/assessment/send-mail-to-collaborator', config)
}
export const skipForm = (assessment_id, form_id, config) => {
	return VRMService.post(`/api/v1/assessment/skip-form/${assessment_id}/${form_id}`, config)
}
export const submitQuestion = (config) => {
	return VRMService.post(`/api/v1/assessment_v2/save-question-answer`, config)
}

export const uploadEvidenceFiles = (config) => {
	return VRMService.post(`/api/v1/file/upload-files`, config)
}
export const getFiles = (config) => {
	return VRMService.post(`/api/v1/file/get-files`, config)
}

export const submitQuestionnaireAuto = (config) => {
	return VRMService.post(`/api/v1/assessment_v2/submit-form-auto`, config)
}

export const submitQuestionnaireManual = (config) => {
	return VRMService.post(`/api/v1/assessment_v2/submit-form-manual`, config)
}

export const resetQuestionVendor = (config) => {
	return VRMService.post(`/api/v1/assessment_v2/reset-question-vendor`, config)
}

//Assignment Risk Manager Form v2

export const getQuestionRiskManager = (config) => {
	return VRMService.get(`/api/v1/assessment_v2/all-question-rm`, config)
}

export const submitQuestionResponseRM = (config) => {
	return VRMService.post(`/api/v1/assessment_v2/save-question-answer-rm`, config)
}

export const submitQuestionnaireRM = (config) => {
	return VRMService.post(`/api/v1/assessment_v2/submit-form-rm`, config)
}

export const submitReviewQuestionRM = (form_id, config) => {
	return VRMService.post(`/api/v1/assessment_v2/save-review-rm/${form_id}`, config)
}

export const resetQuestionRM = (config) => {
	return VRMService.post(`/api/v1/assessment_v2/reset-question-rm`, config)
}