/** @jsxImportSource @emotion/react */

import { customEvents } from '@constants/analytics'
import { validAscents } from '@constants/propTypesValidation'
import { Box, Button as MUIButton } from '@mui/material'
import { ASCENTS } from '@styles/theme'
import { fadeColor } from '@utils/styles'
import mixpanel from 'mixpanel-browser'
import React from 'react'
import { Bars } from 'react-loader-spinner'

const Button = React.memo(
	React.forwardRef(
		(
			{
				id,
				form,
				type,
				fontSize = 14,
				children,
				ascent = null,
				variant = 'contained',
				colorAscent,
				color = 'primary',
				fullWidth = false,
				isLoading = false,
				disabled = false,
				hideLoader = false,
				borderRadius = '8px',
				textTransform,
				fontWeight,
				fontFamily = '"Open Sans", sans-serif',
				boxShadow,
				margin,
				round,
				loaderAscent = 'secondary',
				disableAscent = 'base',
				disableColorAscent = 'wash',
				padding = '.625rem 1.5rem',
				minWidth,
				border,
				display,
				sx,
				...props
			},
			ref
		) => {
			const style = React.useMemo(() => {
				return variant !== 'outlined'
					? {
							backgroundColor: (theme) => (ascent ? `${theme.palette.ascents[ascent]} !important` : undefined),
							color: (theme) => (colorAscent ? theme.palette.ascents[colorAscent] : undefined),
							fontSize,
							padding,
							borderRadius,
							border,
							textTransform,
							fontWeight,
							display,
							fontFamily,
							boxShadow: boxShadow ? `${boxShadow} !important` : undefined,
							margin,
							minWidth,

							'&:hover': {
								backgroundColor: (theme) => theme.palette.ascents[ascent]
							},
							'&:disabled, &.Mui-disabled': {
								cursor: 'not-allowed !important',
								backgroundColor: (theme) =>
									disableAscent ? `${fadeColor(theme.palette.ascents[disableAscent], 0)} !important` : undefined,
								color: (theme) =>
									disableColorAscent
										? `${fadeColor(theme.palette.ascents[disableColorAscent], 0)} !important`
										: undefined,
								fontFamily: '"Open Sans", sans-serif',
								opacity: 0.7,
								pointerEvents: 'auto'
							}
					  }
					: {
							color: (theme) => (colorAscent ? `${theme.palette.ascents[colorAscent]} !important` : undefined),

							borderColor: (theme) => `${theme.palette.ascents[ascent]} !important`,
							fontSize,
							padding,
							borderRadius,
							border,
							textTransform,
							fontWeight,
							display,
							fontFamily,
							boxShadow: boxShadow ? `${boxShadow} !important` : undefined,
							margin,
							minWidth,
							'&:hover': {
								borderColor: (theme) => theme.palette.ascents[ascent]
							},

							'& .MuiTouchRipple-child': {
								background: (theme) => theme.palette?.ascents[ascent],
								opacity: '1 !important'
							}
					  }
			}, [
				variant,
				ascent,
				fontSize,
				colorAscent,
				borderRadius,
				textTransform,
				fontWeight,
				fontFamily,
				boxShadow,
				margin,
				padding,
				disableAscent,
				disableColorAscent,
				round,
				minWidth,
				border,
				display,
				sx
			])

			return (
				<MUIButton
					id={id}
					ref={ref}
					form={form}
					type={type}
					color={color}
					variant={variant}
					fullWidth={fullWidth}
					sx={{
						...style,
						...sx
					}}
					disabled={isLoading || disabled || false}
					{...props}
					onClick={(e) => {
						// console.log('props', props)
						if (props.onClick) {
							props.onClick(e)
						}
						mixpanel.track(customEvents.BUTTON_CLICKED, {
							url: window.location.pathname,
							title: JSON.stringify(children)
						})
					}}
				>
					{isLoading && !hideLoader && (
						<Box className='react-loader-spinner'>
							<Bars
								visible={isLoading && !hideLoader}
								type='Bars'
								color={ASCENTS[loaderAscent] || 'red'}
								height={28}
								width={28}
								timeout={10000}
							/>
						</Box>
					)}
					{children}
				</MUIButton>
			)
		}
	)
)

Button.propTypes = {
	ascent: validAscents
}

export default Button
