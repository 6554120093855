import CloseIcon from '@mui/icons-material/Close'
import { DialogTitle, IconButton } from '@mui/material'
import { styledBy } from '@utils/styles'
import PropTypes from 'prop-types'
import React from 'react'
import Text from '../Text'

const ModaTitle = ({ children, className, onClose, ...props }) => {
	return (
		<DialogTitle
			disableTypography
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				color: (theme) => styledBy('ascent', theme.palette.ascents),
				padding: (theme) => theme.spacing(1, 2)
			}}
			className={className}
			{...props}
		>
			<Text variant='body1'>{children}</Text>
			<IconButton
				aria-label='close'
				sx={{
					padding: (theme) => theme.spacing(1)
				}}
				color='inherit'
				onClick={onClose}
			>
				<CloseIcon />
			</IconButton>
		</DialogTitle>
	)
}

ModaTitle.propTypes = {
	ascent: PropTypes.string,
	children: PropTypes.node,
	onClose: PropTypes.func
}

export default React.memo(ModaTitle)
