import { createSlice } from '@reduxjs/toolkit'
import { resetCompliancesDataReducer } from './reducers'

const initialState = {
	compliances: {
		list: [],
		byKeys: {},
		byIds: {}
	},
	mandates: {
		list: [],
		byKeys: {},
		byIds: {}
	},
	isLoading: false
}

export const compliancesSlice = createSlice({
	initialState,
	name: 'compliances',
	extraReducers: {
		[resetCompliancesDataReducer.pending]: (state) => {
			state.isLoading = true
		},
		[resetCompliancesDataReducer.fulfilled]: (state, action) => {
			state.isLoading = false
			const complianceList = [],
				mandatesList = []
			const complianceByKeys = {},
				mandateByKeys = {}
			const complianceById = {},
				mandateById = {}

			if (Array.isArray(action.payload)) {
				action.payload.map((each) => {
					if (each.type === 'Compliance') {
						complianceList.push(each)
						complianceByKeys[each.key] = each
						complianceById[each.id] = each
					} else {
						mandatesList.push(each)
						mandateByKeys[each.key] = each
						mandateById[each.id] = each
					}
				})
			}

			state.compliances.list = complianceList
			state.compliances.byKeys = complianceByKeys
			state.compliances.byIds = complianceById

			state.mandates.list = mandatesList
			state.mandates.byKeys = mandateByKeys
			state.mandates.byIds = mandateById
		}
	}
})

export const compliancesReducer = compliancesSlice.reducer
