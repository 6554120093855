export default function mappingResponse(response) {
	// const mergedResponse = { ...response }

	const response1 = {
		detail: 'SUCCESS',
		code: 200,
		results: {
			data: {
				organization: {
					id: 1,
					name: 'Zeron',
					slug: 'zeron-8484017',
					api_key: null,
					description: 'Cyber Security Posture Management.',
					avatar: null,
					is_active: true,
					admins: [3],
					region: 'af-south-1',
					address: '9th Floor, Smartworks, Victoria Park,Kolkata',
					city: 'Jamshedpur',
					state: 'West Bengal',
					postal_code: '700012',
					country: '13',
					contact_number: '9007854822',
					website_url: '',
					assessment_token: 'afdbaaad0ced798b46d266bdc2c5dfb3ce6b56ad',
					geographic_location: 'IN,US',
					industry: 'Other',
					company_size: 30,
					annual_revenue: 10000,
					revenue_breakdown: 0.5,
					z_score_target: 6.2,
					zs_target_date: '2023-10-31T08:00:00',
					default_compliance: 4,
					currency: 'INR'
				},
				email: 'demo@zeron.one',
				first_name: 'Demo',
				last_name: 'Admin',
				plan: {
					id: 28,
					applicable_policies: [
						{
							id: 23,
							name: 'HelpScreen',
							description: '',
							slug: 'help',
							api_endpoint: '',
							RouteName: 'HelpScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Others'
						},
						{
							id: 66,
							name: 'Iso27001Screen',
							description: null,
							slug: 'iso-27001',
							api_endpoint: null,
							RouteName: 'Iso27001Screen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Compliance'
						},
						{
							id: 60,
							name: 'Soc2Screen',
							description: 'Soc2Screen',
							slug: 'soc2',
							api_endpoint: null,
							RouteName: 'Soc2Screen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Compliance'
						},
						{
							id: 80,
							name: 'AzureScreen',
							description: null,
							slug: 'azure-screen',
							api_endpoint: null,
							RouteName: 'AzureScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Cloud Monitor'
						},
						{
							id: 79,
							name: 'GcpScreen',
							description: null,
							slug: 'gdpr',
							api_endpoint: null,
							RouteName: 'GcpScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Cloud Monitor'
						},
						{
							id: 78,
							name: 'AwsScreen',
							description: null,
							slug: 'aws-screen',
							api_endpoint: null,
							RouteName: 'AwsScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Cloud Monitor'
						},
						{
							id: 84,
							name: 'RBIMandatesOverviewScreen',
							description: null,
							slug: 'rbi-nbfc',
							api_endpoint: null,
							RouteName: 'RBIMandatesOverviewScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Business Posture'
						},
						{
							id: 16,
							name: 'DetectionNestedScreen',
							description: null,
							slug: 'detections',
							api_endpoint: null,
							RouteName: 'DetectionNestedScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Defence'
						},
						{
							id: 9,
							name: 'Hosts',
							description: null,
							slug: 'host',
							api_endpoint: null,
							RouteName: 'HostNestedScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Defence'
						},
						{
							id: 17,
							name: 'SecurityEventsNestedScreen',
							description: null,
							slug: 'securityEvents',
							api_endpoint: null,
							RouteName: 'SecurityEventsNestedScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Defence'
						},
						{
							id: 7,
							name: 'SecurityAssessmentScreen',
							description: null,
							slug: 'security-assessment',
							api_endpoint: null,
							RouteName: 'SecurityAssessmentScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Attack surface'
						},
						{
							id: 27,
							name: 'SecurityReportScreen',
							description: null,
							slug: 'securityReportScreen',
							api_endpoint: null,
							RouteName: 'SecurityReportScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Attack surface'
						},
						{
							id: 6,
							name: 'dashboard',
							description: null,
							slug: 'dashboard',
							api_endpoint: null,
							RouteName: 'DashboardScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Dashboard'
						},
						{
							id: 76,
							name: 'CreatePdfReportScreen',
							description: null,
							slug: 'pdf-report',
							api_endpoint: null,
							RouteName: 'CreatePdfReportScreen',
							canRead: null,
							canCreate: null,
							canUpdate: null,
							canDelete: null,
							group: 'More Options'
						},
						{
							id: 88,
							name: 'Configure notifications',
							description: 'Allows user to configure notifications.',
							slug: 'configure-notifications',
							api_endpoint: '""',
							RouteName: 'NotificationSettingsScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'More Options'
						},
						{
							id: 56,
							name: 'InternalTicketScreen',
							description: '',
							slug: 'InternalTicketScreen',
							api_endpoint: '',
							RouteName: 'InternalTicketScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'More Options'
						},
						{
							id: 26,
							name: 'FeedbackScreen',
							description: '',
							slug: 'feedback',
							api_endpoint: '',
							RouteName: 'FeedbackScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'More Options'
						},
						{
							id: 25,
							name: 'MyAccountScreen',
							description: '',
							slug: 'account',
							api_endpoint: '',
							RouteName: 'MyAccountScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'More Options'
						},
						{
							id: 24,
							name: 'BillingScreen',
							description: '',
							slug: 'billing',
							api_endpoint: '',
							RouteName: 'BillingScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'More Options'
						},
						{
							id: 74,
							name: 'PdfReportScreen',
							description: null,
							slug: 'pdf-report',
							api_endpoint: null,
							RouteName: 'PdfReportScreen',
							canRead: null,
							canCreate: null,
							canUpdate: null,
							canDelete: null,
							group: 'More Options'
						},
						{
							id: 58,
							name: 'NotificationsScreen',
							description: '',
							slug: 'notifications',
							api_endpoint: '',
							RouteName: 'NotificationsScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'More Options'
						},
						{
							id: 35,
							name: 'GdprScreen',
							description: null,
							slug: 'gdpr',
							api_endpoint: null,
							RouteName: 'GdprScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Compliance'
						},
						{
							id: 39,
							name: 'PciDssScreen',
							description: null,
							slug: 'pci-dss',
							api_endpoint: null,
							RouteName: 'PciDssScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Compliance'
						},
						{
							id: 51,
							name: 'HippaScreen',
							description: null,
							slug: 'hipaa',
							api_endpoint: null,
							RouteName: 'HippaScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Compliance'
						},
						{
							id: 43,
							name: 'NistScreen',
							description: null,
							slug: 'nist',
							api_endpoint: null,
							RouteName: 'NistScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Compliance'
						},
						{
							id: 82,
							name: 'IntegrationScreen',
							description: null,
							slug: 'integration-screen',
							api_endpoint: null,
							RouteName: 'IntegrationScreen',
							canRead: null,
							canCreate: null,
							canUpdate: null,
							canDelete: null,
							group: 'Others'
						},
						{
							id: 4,
							name: 'Administration Management',
							description: null,
							slug: 'administration-management',
							api_endpoint: null,
							RouteName: 'AdministrationScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Others'
						},
						{
							id: 94,
							name: 'Business Risk Posture Screen',
							description: 'Displays business risk posture',
							slug: 'business-risk-posture-screen',
							api_endpoint: '""',
							RouteName: 'BusinessRiskPostureScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Business Posture'
						},
						{
							id: 99,
							name: 'SebiMandatesOverviewScreen',
							description: 'SebiMandatesOverviewScreen',
							slug: 'sebi',
							api_endpoint: 'sebi',
							RouteName: 'SebiMandatesOverviewScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Business Posture'
						},
						{
							id: 95,
							name: 'IntegrationViewScreen',
							description: 'IntegrationViewScreen',
							slug: 'integration-view-screen',
							api_endpoint: null,
							RouteName: 'IntegrationViewScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Defence'
						},
						{
							id: 93,
							name: 'RiskMitigationScreen',
							description: 'Displays risk mitigation.',
							slug: 'risk-mitigation',
							api_endpoint: '""',
							RouteName: 'RiskMitigationScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Defence'
						}
					],
					name: 'ESSENTIALS USD Monthly',
					description:
						'For mid-sized organizations who want to spend more time on building their business and less on running behind managing cyber risks and regulatory mandates.',
					duration_days: 30,
					price: '1000.00',
					currency_code: 'USD',
					created_at: '2023-04-23T15:43:13.580653',
					last_updated_on: '2023-04-26T19:49:27.925987',
					public: true,
					upgrade_priority: 1,
					item_price_id: 'ESSENTIALS-USD-Monthly',
					item_id: 'ESSENTIALS',
					api_hit_limit: null,
					storage_quota: null,
					attack_limit: 100,
					agent_quota: 200
				},
				user: {
					id: 3,
					username: 'demouser',
					first_name: 'Demo',
					last_name: 'Admin',
					email: 'demo@zeron.one',
					contact_id: '103851000000200003',
					is_staff: false,
					is_active: true,
					tier: null,
					avatar: 'https://zerondevasset.blob.core.windows.net/devzeron/pii-uploads/photo-1530092285049-1c42085fd395_AHZh1Pk.jpg?se=2023-10-15T20%3A14%3A06Z&sp=r&sv=2023-01-03&sr=b&sig=ruyRMZsY0QcasUXaEbrNJyLQ2PMzuZsd5UW7Xgvs8Gk%3D',
					job_position: null,
					taken_walkthrough: true,
					country: 'AR',
					mfa_enabled: 'false',
					user_id: 1
				},
				role: {
					id: 1,
					name: 'Admin',
					description: 'Administrative role for `Zeron`',
					policy_list: [
						{
							id: 4,
							name: 'Administration Management',
							description: null,
							slug: 'administration-management',
							api_endpoint: null,
							RouteName: 'AdministrationScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Others'
						},
						{
							id: 6,
							name: 'dashboard',
							description: null,
							slug: 'dashboard',
							api_endpoint: null,
							RouteName: 'DashboardScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Dashboard'
						},
						{
							id: 7,
							name: 'SecurityAssessmentScreen',
							description: null,
							slug: 'security-assessment',
							api_endpoint: null,
							RouteName: 'SecurityAssessmentScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Attack surface'
						},
						{
							id: 9,
							name: 'Hosts',
							description: null,
							slug: 'host',
							api_endpoint: null,
							RouteName: 'HostNestedScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Defence'
						},
						{
							id: 16,
							name: 'DetectionNestedScreen',
							description: null,
							slug: 'detections',
							api_endpoint: null,
							RouteName: 'DetectionNestedScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Defence'
						},
						{
							id: 17,
							name: 'SecurityEventsNestedScreen',
							description: null,
							slug: 'securityEvents',
							api_endpoint: null,
							RouteName: 'SecurityEventsNestedScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Defence'
						},
						{
							id: 23,
							name: 'HelpScreen',
							description: '',
							slug: 'help',
							api_endpoint: '',
							RouteName: 'HelpScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Others'
						},
						{
							id: 24,
							name: 'BillingScreen',
							description: '',
							slug: 'billing',
							api_endpoint: '',
							RouteName: 'BillingScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'More Options'
						},
						{
							id: 25,
							name: 'MyAccountScreen',
							description: '',
							slug: 'account',
							api_endpoint: '',
							RouteName: 'MyAccountScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'More Options'
						},
						{
							id: 26,
							name: 'FeedbackScreen',
							description: '',
							slug: 'feedback',
							api_endpoint: '',
							RouteName: 'FeedbackScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'More Options'
						},
						{
							id: 27,
							name: 'SecurityReportScreen',
							description: null,
							slug: 'securityReportScreen',
							api_endpoint: null,
							RouteName: 'SecurityReportScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Attack surface'
						},
						{
							id: 35,
							name: 'GdprScreen',
							description: null,
							slug: 'gdpr',
							api_endpoint: null,
							RouteName: 'GdprScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Compliance'
						},
						{
							id: 39,
							name: 'PciDssScreen',
							description: null,
							slug: 'pci-dss',
							api_endpoint: null,
							RouteName: 'PciDssScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Compliance'
						},
						{
							id: 43,
							name: 'NistScreen',
							description: null,
							slug: 'nist',
							api_endpoint: null,
							RouteName: 'NistScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Compliance'
						},
						{
							id: 51,
							name: 'HippaScreen',
							description: null,
							slug: 'hipaa',
							api_endpoint: null,
							RouteName: 'HippaScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'Compliance'
						},
						{
							id: 56,
							name: 'InternalTicketScreen',
							description: '',
							slug: 'InternalTicketScreen',
							api_endpoint: '',
							RouteName: 'InternalTicketScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'More Options'
						},
						{
							id: 58,
							name: 'NotificationsScreen',
							description: '',
							slug: 'notifications',
							api_endpoint: '',
							RouteName: 'NotificationsScreen',
							canRead: true,
							canCreate: true,
							canUpdate: true,
							canDelete: true,
							group: 'More Options'
						}
					],
					policies: [4, 6, 7, 9, 16, 17, 23, 24, 25, 26, 27, 35, 39, 43, 51, 56, 58]
				}
			}
		}
	}

	const mappedPermissions = response.results.data.permissions?.permission_ids.map((item) => {
		return {
			id: item.permission_id,
			name: item.permission_name,
			slug: item.permission_slug,
			RouteName: item.route_name,
			group: item.permission_group
			// Add any other properties you want to include
		}
	})

	const user_first = response.results.data.user.first_name
	const user_last = response.results.data.user.last_name
	const org_id = response.results.data.user.org_id
	const orgName = response.results.data.user.org_name
	const email_id = response.results.data.user.email
	const role_name = response.results.data.permissions.role_name
	const role_id = response.results.data.permissions.role_id
	const mfa_flag = response.results.data.user.mfa_enabled
	const user_id = response.results.data.user.user_id

	response1.results.data.role.name = role_name
	response1.results.data.role.id = role_id

	response1.results.data.user.first_name = user_first
	response1.results.data.user.last_name = user_last
	response1.results.data.user.email = email_id
	response1.results.data.user.id = org_id
	response1.results.data.user.user_id = user_id
	response1.results.data.user.mfa_enabled = mfa_flag

	response1.results.data.first_name = user_first
	response1.results.data.last_name = user_last
	response1.results.data.email = email_id

	response1.results.data.role.policy_list = mappedPermissions

	response1.results.data.organization.name = orgName
	// mergedResponse.results.data.user.permissions = mappedPermission

	return response1
}
