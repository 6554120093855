import { createSlice } from '@reduxjs/toolkit'
import { getMillisecondsByUnit, timeSince } from '@utils/other'

const initialState = {
	quickFilter: {
		value: 2,
		unit: 'hour',
		displayAs: 'hour',
		index: 0,
		type: 'fixed',
		label: 'Last 2 hours'
	},
	intervals: {
		value: 0,
		unit: 'seconds',
		displayAs: 'seconds',
		index: -1,
		type: 'intervals',
		label: ''
	},
	timeRange: {
		startDate: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
		endDate: new Date().toISOString()
	}
}

export const timeFrame = createSlice({
	name: 'quickFilter',
	initialState,
	reducers: {
		updateCustomTimeRange: (state, action) => {
			// To change look of the buttons as inactive in UI
			state.quickFilter.index = -1
			state.quickFilter.label = ''

			state.intervals.index = -1
			state.intervals.label = ''

			// Expecting the dates in ISO Format here
			state.timeRange.startDate = action.payload.startDate
			state.timeRange.endDate = action.payload.endDate
		},
		updateQuickFilter: (state, action) => {
			const diff = timeSince(new Date(new Date().getTime() - getMillisecondsByUnit(action.payload.value, action.payload.unit)), true)

			state.quickFilter.value = action.payload.value
			state.quickFilter.unit = action.payload.unit
			state.quickFilter.label = `Last ${Math.ceil(diff)} ${action.payload.displayAs}`
			state.quickFilter.type = action.payload.type
			state.quickFilter.index = action.payload.index

			// Turn off intervals
			state.intervals.index = -1
			state.intervals.label = ''

			state.timeRange.startDate = new Date(
				new Date().getTime() -
					getMillisecondsByUnit(action.payload.value, action.payload.unit) -
					getMillisecondsByUnit(state.intervals.value, state.intervals.unit)
			).toISOString()

			state.timeRange.endDate = new Date().toISOString()
		},
		updateInterval: (state, action) => {
			const diff = timeSince(new Date(new Date().getTime() - getMillisecondsByUnit(action.payload.value, action.payload.unit)), true)

			state.intervals.value = action.payload.value
			state.intervals.unit = action.payload.unit
			state.intervals.label = `Every ${Math.ceil(diff)} ${action.payload.displayAs}`
			state.intervals.type = action.payload.type
			state.intervals.index = action.payload.index

			// Turn off fixed range
			state.quickFilter.index = -1
			state.quickFilter.label = ''

			state.timeRange.startDate = new Date(
				new Date().getTime() - getMillisecondsByUnit(action.payload.value, action.payload.unit)
			).toISOString()

			state.timeRange.endDate = new Date().toISOString()
		},
		renewTimeFrame: (state) => {
			state.timeRange.startDate =
				state.intervals.index > -1
					? new Date(new Date().getTime() - getMillisecondsByUnit(state.intervals.value, state.intervals.unit)).toISOString()
					: new Date(new Date().getTime() - getMillisecondsByUnit(state.quickFilter.value, state.quickFilter.unit)).toISOString()

			state.timeRange.endDate = new Date().toISOString()
		}
	}
})

// Action creators are generated for each case reducer function
export const { updateCustomTimeRange, updateQuickFilter, updateInterval, renewTimeFrame } = timeFrame.actions

export const timeFrameReducer = timeFrame.reducer
