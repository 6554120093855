import HeaderLogo from '@assets/icons/HeaderLogo.png'
import { Box, DialogTitle } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import PropTypes from 'prop-types'
import React from 'react'
import ModaTitle from './ModalTitle'
const AppModal = ({
	children,
	onClose,
	open,
	className,
	title,
	hideClose,
	textAscent,
	ActionComponent,
	CustomHeaderComponent,
	showActions = true,
	hideHeader = false,
	fullWidth = false,
	contentClassName,
	contentProps,
	minHeight,
	titleProps,
	showOverflowContent = false,
	isHeaderShown = false,
	...props
}) => {
	React.useEffect(() => {
		const root = document.getElementById('root')

		if (open) {
			root.classList.add('blur-it')
		} else {
			root.classList.remove('blur-it')
		}
	}, [open])

	return (
		<>
			<Dialog
				fullWidth={fullWidth}
				maxWidth={'xl'}
				open={open}
				onClose={onClose}
				aria-labelledby='modal'
				minHeight={250}
				disableEnforceFocus
				className={className}
				sx={{
					// '& .MuiDialog-paper': {
					// 	minWidth: '25rem',
					// 	minHeight: '10rem',
					// 	borderRadius: '16px',
					// 	// Set the background here if you want it behind all contents of the dialog
					// 	backgroundImage: `url(${HeaderLogo})`,
					// 	backgroundSize: 'cover',
					// 	backgroundPosition: 'center',
					// 	backgroundRepeat: 'no-repeat',
					// },
					'& .MuiDialog-paperScrollPaper': {
						minWidth: '29rem',
						minHeight: '10rem',
						overflow: showOverflowContent ? 'visible' : 'hidden'
					},
					'& .MuiDialogContent-root': {
						overflow: showOverflowContent ? 'visible' : 'auto'
					},
					'& .MuiPaper-rounded': {
						borderRadius: '16px'
					},
					'& .MuiDialogActions-root': {
						padding: '12px 16px 16px 16px'
					}
				}}
				{...props}
			>
				{
					isHeaderShown &&
					<Box display={'flex'} mt={0} style={{ paddingTop: 0, marginTop: -37.2 }} zIndex={2} position={'cover'}>
						<img src={HeaderLogo} width={'100%'} position={'absolute'} height={'170rem'} alt='Header Logo' />
					</Box>
				}
				{!hideHeader && (
					<ModaTitle ascent={textAscent} onClose={onClose} {...titleProps}>
						{title}
					</ModaTitle>
				)}
				{
					CustomHeaderComponent && (
						<DialogTitle>
							{CustomHeaderComponent}
						</DialogTitle>
					)
				}
				<Box
					component={DialogContent}
					className={contentClassName}
					{...contentProps}
					sx={{
						padding: '0.5rem 1rem 1rem 1rem !important',
						minHeight,
						...contentProps?.sx
					}}
				>
					{children}
				</Box>
				{showActions && (
					<DialogActions>
						{!hideClose && (
							<Button onClick={onClose} colorAscent='textDark'>
								Close
							</Button>
						)}
						{ActionComponent}
					</DialogActions>
				)}
			</Dialog >
		</>
	)
}

AppModal.propTypes = {
	onClose: PropTypes.func,
	showActions: PropTypes.bool,
	title: PropTypes.string,
	hideHeader: PropTypes.bool,
	contentProps: PropTypes.object,
	ActionComponent: PropTypes.node,
	showOverflowContent: PropTypes.bool
}

export default React.memo(AppModal)
