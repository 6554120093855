import { combineReducers, configureStore } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import persistStore from 'redux-persist/es/persistStore'
import storage from 'redux-persist/lib/storage'
import { authReducer } from './auth/slice'
import { compliancesReducer } from './compliances/slice'
import { networkStatusReducer } from './networkStatus/slice'
import { policyListsReducer } from './policies/slice'
import { refreshReducer } from './refresh/slice'
import { roleListsReducer } from './roles/slice'
import { securityFactsReducer } from './securityFacts/slice'
import { timeFrameReducer } from './timeFrame/slice'

const reducers = combineReducers({
	// Add reducers here
	reloadToken: refreshReducer,
	isOnline: networkStatusReducer,
	timeFrame: timeFrameReducer,
	securityFacts: securityFactsReducer,
	auth: authReducer,
	roles: roleListsReducer,
	policy: policyListsReducer,
	compliances: compliancesReducer
})

const reducer = persistReducer(
	{
		key: 'Zeron',
		storage,
		whitelist: ['auth', 'plans']
	},
	reducers
)

export const store = configureStore({
	reducer,
	devTools: import.meta.env.VITE_APP_DISABLE_LOGS !== 'true' && typeof window !== 'undefined'
})
export const persistor = persistStore(store)

export default store
